import { Modal } from 'antd';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import DiscursiveQuestion from '@portal/components/v1/Question/DiscursiveQuestion/DiscursiveQuestion';

import { ChatActions } from '@portal/actions';
import { SPECIFIC_QUESTION } from '@portal/actions/actionTypes';
import { useReduxState } from '@portal/hooks/useReduxState';
import { IRouteParams } from '@portal/models/route-params';
import { translate } from '@portal/services/i18n';

import MessageContainer from '../MessageContainer/MesssageContainer';
import { RenderCondition } from '@portal/utils';

const Conversation: React.FC = () => {
  const { me } = useReduxState().auth;
  const { messages } = useReduxState().chat;
  const { questionAnswered } = useReduxState().activity;

  const dispatch = useDispatch();

  const { privateChatId } = useParams<IRouteParams>();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [questionModalVisible, setQuestionModalVisible] = useState(false);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    let checkNewMessages: NodeJS.Timeout;
    if (privateChatId) {
      checkNewMessages = setInterval(() => {
        dispatch(ChatActions.reloadMessages(privateChatId));
      }, 20000);
      dispatch(ChatActions.reloadMessages(privateChatId));
    }

    return () => {
      clearInterval(checkNewMessages);
    };
  }, [dispatch, privateChatId]);

  useEffect(() => {
    if (messages.length > 1) {
      scrollToBottom();
    }
  }, [scrollToBottom, messages]);

  const handleCloseModal = useCallback(() => {
    setQuestionModalVisible(false);
    dispatch({
      type: SPECIFIC_QUESTION,
      payload: {} as models.Questions,
    });
  }, [dispatch]);

  return (
    <>
      <div className="m-0 conversation-component">
        {messages.map((message) => (
          <MessageContainer
            key={message.id}
            me={me?.id === message.userId}
            message={message.message}
            photoUrl={message.photoUrl}
            time={DateTime.fromISO(message.createdAt).toLocaleString({
              hour: '2-digit',
              minute: '2-digit',
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}
            metadata={message.metadata}
            toggleModal={() => setQuestionModalVisible((state) => !state)}
          />
        ))}
        <div ref={messagesEndRef} />
        <Modal
          title={translate('PAGES.CHAT.MODAL.TITLE')}
          visible={questionModalVisible}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          okText={translate('PAGES.CHAT.MODAL.OK_TEXT')}
          cancelText={translate('PAGES.CHAT.MODAL.CANCEL_TEXT')}
          zIndex={2}
        >
          <RenderCondition condition={!!questionAnswered?.answers?.length}>
            <div className="activity-container">
              <DiscursiveQuestion
                setCommentValues={() => ''}
                id={questionAnswered.id}
                description={questionAnswered.description}
                answeredQuestion={questionAnswered}
                readOnly={true}
              />
            </div>
          </RenderCondition>
        </Modal>
      </div>
    </>
  );
};

export default Conversation;
