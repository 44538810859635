import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ACTIVITY_QUESTIONS_ANSWERED } from '@portal/actions/actionTypes';

import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import RenderStatusDate from '@portal/components/v1/RenderStatusDate/RenderStatusDate';

import { translate } from '@portal/services/i18n';
import { ANSWER_TABS } from '@portal/enum/answerTabs';
import { ProductType } from '@portal/enum/productType';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '~/services/storage';
import { RenderCondition } from '@portal/utils';
import { EditorHTML } from '@portal/components/v2';

interface IProps {
  expectedAnswer?: string;
  answerRules?: {
    description: number;
    file: number;
  };
  answeredFields?:
    | {
        id: string;
        answer: string;
        fileUrl: string;
        createdAt?: Date | string;
      }
    | undefined;
  setAnswer: (answerType: number, value: string, referralId: string, activityQuestionTestOptionId: string) => void;
  alertRequiredField?:
    | {
        answer: boolean;
        fileUrl: boolean;
      }
    | undefined;
  readOnly?: boolean;
  isSolution?: boolean;
  disabled?: boolean;
  isUserCollapse?: boolean;
  isHistoric?: boolean;
  activityQuestionTestOptionId?: string;
  closureTaskAnswer?: models.ClosureTask;
  isNeededToRedo?: boolean;
  userAnswer?: models.ClosureTask;
}

const AnswerQuestion: React.FC<IProps> = ({
  expectedAnswer,
  setAnswer,
  alertRequiredField,
  answeredFields,
  readOnly,
  isSolution,
  isUserCollapse,
  disabled,
  isHistoric,
  activityQuestionTestOptionId,
  closureTaskAnswer,
  userAnswer,
  isNeededToRedo,
}) => {
  const {
    auth: { me },
    activity: { activityAnswers },
    closureTask: { closureTaskAnswered },
  } = useReduxState();

  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [attachmentURL, setAttachmentURL] = useState('');
  const [editAnswer, setEditAnswer] = useState(true);

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  useEffect(() => {
    if (closureTaskAnswered && !isSolution && !isUserCollapse) {
      dispatch({ payload: {}, type: ACTIVITY_QUESTIONS_ANSWERED });
    }
  }, [closureTaskAnswered]);

  useEffect(() => {
    setAnswer(ANSWER_TABS.TEXT, text, answeredFields?.id as string, activityQuestionTestOptionId as string);
  }, [text]);

  useEffect(() => {
    setAnswer(ANSWER_TABS.ATTACHMENTS, attachmentURL, answeredFields?.id as string, answeredFields?.id as string);
  }, [attachmentURL]);

  useEffect(() => {
    if (closureTaskAnswer) {
      setText(closureTaskAnswer?.answer as string);
    }
  }, [closureTaskAnswer]);

  useEffect(() => {
    if (userAnswer) {
      setText(userAnswer?.answer as string);
    }
  }, [userAnswer]);

  useEffect(() => {
    if (answeredFields?.answer && editAnswer) {
      setText(answeredFields?.answer);
    }

    if (answeredFields?.fileUrl) {
      setAttachmentURL(answeredFields?.fileUrl);
    }
  }, [answeredFields, editAnswer]);

  const isDisabled = () => {
    if (readOnly && isNeededToRedo) {
      return true;
    }

    if (!readOnly && isNeededToRedo) {
      return false;
    }

    if (!!answeredFields || readOnly || isHistoric || disabled) {
      return true;
    }

    return false;
  };

  const onAnswerChange = (value: string) => {
    const cleanedValue = value.trim() === '<p><br></p>' ? '' : value;
    setText(cleanedValue);
    setEditAnswer(false);
  };

  const isRevision = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  const currentAnswer = activityAnswers?.questions?.[0]?.answers?.[0];

  return (
    <>
      <RenderCondition condition={alertRequiredField?.answer || alertRequiredField?.fileUrl}>
        <p className="required-field-text">
          {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.REQUIRED_FIELD_TEXT')}
        </p>
      </RenderCondition>
      <div className="answer-question-container">
        <RenderCondition condition={isRevision}>
          <div className="answer-question-container__review-tutor">
            <RenderCondition condition={!!expectedAnswer}>
              <div className="mt-4">
                <span className="answer-question-container__review-tutor__expected-answer">
                  {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.EXPECTED_ANSWER')}
                </span>
                <span className="answer-question-container__review-tutor__expected-answer-student">
                  <RenderHTML htmlText={expectedAnswer || ''} />
                </span>
              </div>
            </RenderCondition>

            <RenderCondition condition={!isSolution}>
              <div className="mt-4">
                <span className="answer-question-container__review-tutor__answerStudent">
                  {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.STUDENT_ANSWER')}
                </span>
                <RenderHTML htmlText={text} />
              </div>
            </RenderCondition>
          </div>
        </RenderCondition>
        <RenderCondition condition={!isRevision}>
          <div className="answer-question-container__input-textarea-container">
            <EditorHTML
              onChange={onAnswerChange}
              isDisabled={isDisabled()}
              value={userAnswer?.answer ?? answeredFields?.answer ?? ''}
            />
          </div>
        </RenderCondition>

        <RenderCondition condition={!!activityAnswers?.id && !!currentAnswer?.createdAt}>
          <div className="answer-question-container__render-status-date">
            <RenderStatusDate
              isAnswerDate
              date={(answeredFields?.createdAt ?? currentAnswer?.updatedAt ?? currentAnswer?.createdAt)?.toString()}
              fontSizeAnswer="10px"
              isSolution={isSolution}
            />
          </div>
        </RenderCondition>

        <RenderCondition condition={!!closureTaskAnswered?.id && !!closureTaskAnswered?.createdAt}>
          <div className="answer-question-container__render-status-date">
            <RenderStatusDate
              isAnswerDate
              date={closureTaskAnswered?.createdAt?.toString()}
              fontSizeAnswer="10px"
              isSolution={isSolution}
            />
          </div>
        </RenderCondition>
      </div>
    </>
  );
};

export default AnswerQuestion;
