/* eslint-disable max-len */
import React, { ComponentProps } from 'react';

export const IcCertificateMedallion = (props: ComponentProps<'svg'>) => (
  <svg width="76" height="77" viewBox="0 0 76 77" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_35909_1191)">
      <path
        d="M34.1491 6.26191C36.2499 4.07336 39.7501 4.07336 41.8509 6.26191L42.2649 6.69324C43.573 8.05591 45.5038 8.62583 47.3422 8.19188L47.9138 8.05695C50.8541 7.36288 53.784 9.24515 54.375 12.2079L54.5126 12.8978C54.8789 14.734 56.1805 16.2441 57.9426 16.8772L58.5861 17.1084C61.4077 18.1221 62.8412 21.2602 61.7602 24.0567L61.479 24.7842C60.8087 26.518 61.0887 28.4756 62.2181 29.9521L62.6885 30.5671C64.5061 32.9433 64.0163 36.3491 61.6027 38.1168L61.0069 38.5531C59.5032 39.6544 58.6828 41.4603 58.8427 43.3174L58.9073 44.0682C59.1653 47.0663 56.8955 49.685 53.8912 49.8554L53.2647 49.8909C51.386 49.9974 49.7022 51.0852 48.8328 52.7541L48.5346 53.3265C47.1346 56.014 43.7808 56.9984 41.1504 55.4939L40.6502 55.2078C39.0082 54.2687 36.9918 54.2687 35.3498 55.2078L34.8496 55.4939C32.2192 56.9984 28.8654 56.014 27.4654 53.3265L27.1672 52.7541C26.2978 51.0852 24.614 49.9974 22.7353 49.8909L22.1088 49.8554C19.1045 49.685 16.8347 47.0663 17.0927 44.0682L17.1573 43.3174C17.3171 41.4603 16.4968 39.6544 14.993 38.5531L14.3973 38.1168C11.9837 36.3491 11.4939 32.9433 13.3115 30.5671L13.7819 29.9521C14.9113 28.4757 15.1913 26.518 14.521 24.7842L14.2398 24.0567C13.1587 21.2602 14.5923 18.1221 17.4139 17.1084L18.0574 16.8772C19.8195 16.2441 21.1211 14.734 21.4874 12.8978L21.625 12.2079C22.216 9.24515 25.1459 7.36288 28.0862 8.05695L28.6578 8.19188C30.4962 8.62583 32.427 8.05591 33.7351 6.69324L34.1491 6.26191Z"
        fill="url(#paint0_linear_35909_1191)"
      />
      <path
        d="M34.1491 6.26191C36.2499 4.07336 39.7501 4.07336 41.8509 6.26191L42.2649 6.69324C43.573 8.05591 45.5038 8.62583 47.3422 8.19188L47.9138 8.05695C50.8541 7.36288 53.784 9.24515 54.375 12.2079L54.5126 12.8978C54.8789 14.734 56.1805 16.2441 57.9426 16.8772L58.5861 17.1084C61.4077 18.1221 62.8412 21.2602 61.7602 24.0567L61.479 24.7842C60.8087 26.518 61.0887 28.4756 62.2181 29.9521L62.6885 30.5671C64.5061 32.9433 64.0163 36.3491 61.6027 38.1168L61.0069 38.5531C59.5032 39.6544 58.6828 41.4603 58.8427 43.3174L58.9073 44.0682C59.1653 47.0663 56.8955 49.685 53.8912 49.8554L53.2647 49.8909C51.386 49.9974 49.7022 51.0852 48.8328 52.7541L48.5346 53.3265C47.1346 56.014 43.7808 56.9984 41.1504 55.4939L40.6502 55.2078C39.0082 54.2687 36.9918 54.2687 35.3498 55.2078L34.8496 55.4939C32.2192 56.9984 28.8654 56.014 27.4654 53.3265L27.1672 52.7541C26.2978 51.0852 24.614 49.9974 22.7353 49.8909L22.1088 49.8554C19.1045 49.685 16.8347 47.0663 17.0927 44.0682L17.1573 43.3174C17.3171 41.4603 16.4968 39.6544 14.993 38.5531L14.3973 38.1168C11.9837 36.3491 11.4939 32.9433 13.3115 30.5671L13.7819 29.9521C14.9113 28.4757 15.1913 26.518 14.521 24.7842L14.2398 24.0567C13.1587 21.2602 14.5923 18.1221 17.4139 17.1084L18.0574 16.8772C19.8195 16.2441 21.1211 14.734 21.4874 12.8978L21.625 12.2079C22.216 9.24515 25.1459 7.36288 28.0862 8.05695L28.6578 8.19188C30.4962 8.62583 32.427 8.05591 33.7351 6.69324L34.1491 6.26191Z"
        fill="url(#paint1_linear_35909_1191)"
        fill-opacity="0.1"
      />
      <path
        d="M18.5869 30.6294C18.5869 19.9077 27.2785 11.2161 38.0002 11.2161C48.7219 11.2161 57.4135 19.9077 57.4135 30.6294V31.0286C57.4135 41.7503 48.7219 50.4419 38.0002 50.4419C27.2785 50.4419 18.5869 41.7503 18.5869 31.0286V30.6294Z"
        fill="black"
        fill-opacity="0.04"
      />
      <path
        d="M20.58 30.6294C20.58 21.0084 28.3793 13.2091 38.0002 13.2091C47.6211 13.2091 55.4204 21.0084 55.4204 30.6294V31.0286C55.4204 40.6495 47.6211 48.4488 38.0002 48.4488C28.3793 48.4488 20.58 40.6495 20.58 31.0286V30.6294Z"
        stroke="white"
        stroke-opacity="0.3"
        stroke-width="3.98616"
      />
      <path
        opacity="0.8"
        d="M34.8006 34.3728L31.073 30.6069C30.8723 30.4037 30.5998 30.2895 30.3157 30.2895C30.0315 30.2895 29.7591 30.4037 29.5584 30.6069C29.1394 31.0302 29.1394 31.7139 29.5584 32.1371L34.0486 36.6736C34.4676 37.0968 35.1443 37.0968 35.5633 36.6736L46.9286 25.1914C47.3475 24.7682 47.3475 24.0844 46.9286 23.6612C46.7279 23.458 46.4554 23.3438 46.1712 23.3438C45.8871 23.3438 45.6146 23.458 45.4139 23.6612L34.8006 34.3728Z"
        fill="white"
        stroke="#FDFDFD"
        stroke-width="0.797231"
      />
      <path
        d="M57.6756 31.0067C57.6756 41.8733 48.8665 50.6824 37.9999 50.6824C27.1333 50.6824 18.3242 41.8733 18.3242 31.0067C18.3242 20.1402 27.1333 11.3311 37.9999 11.3311C48.8665 11.3311 57.6756 20.1402 57.6756 31.0067ZM22.375 31.0067C22.375 39.6361 29.3705 46.6317 37.9999 46.6317C46.6293 46.6317 53.6248 39.6361 53.6248 31.0067C53.6248 22.3773 46.6293 15.3818 37.9999 15.3818C29.3705 15.3818 22.375 22.3773 22.375 31.0067Z"
        fill="url(#paint2_linear_35909_1191)"
        fill-opacity="0.5"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_35909_1191"
        x="-2"
        y="-1.75"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.690196 0 0 0 0 0.12549 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_35909_1191" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_35909_1191" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_35909_1191"
        x1="19.8328"
        y1="7.49439"
        x2="50.5485"
        y2="55.9091"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE092" />
        <stop offset="1" stop-color="#E3A302" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_35909_1191"
        x1="0.382873"
        y1="-2.12476"
        x2="42.9224"
        y2="73.8045"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_35909_1191"
        x1="37.9999"
        y1="11.3311"
        x2="37.9999"
        y2="50.6824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.0001" stop-color="white" stop-opacity="0.81" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
