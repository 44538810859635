import React, { useCallback, useEffect, useState } from 'react';

import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import Feedback from '@portal/components/v1/Question/Feedback/Feedback';
import DragAndDrop from '@portal/components/v1/UI/Molecules/DragAndDrop/DragAndDrop';
import AnswerQuestion from '@portal/components/v1/Question/AnswerQuestion/AnswerQuestion';

import { ANSWER_TABS } from '@portal/enum/answerTabs';
import { QUESTION_TYPE } from '@portal/enum/questionType';
import { REVIEW_STATUS_TYPE } from '@portal/enum/reviewStatusType';

import { isDashTutor } from '@portal/utils/isDashTutor';
import { isFileUploadType } from '@portal/utils/isFileUploadType';
import { isFileDownloadType } from '@portal/utils/isFileDownloadType';
import { isDiscursiveQuestion } from '@portal/utils/isDiscursiveQuestion';
import ReviewTutor from '@portal/components/v1/UI/Molecules/ReviewTutor/ReviewTutor';

import { translate } from '@portal/services/i18n';
import * as StorageService from '~/services/storage';
import { useReduxState } from '@portal/hooks/useReduxState';

import { DownloadOutlined } from '@ant-design/icons';
import { ProductType } from '@portal/enum/productType';
import { UserActionType } from '@portal/enum/userActionType';
import { RenderCondition } from '@portal/utils';

interface IProps {
  id: string;
  idUser?: string;
  description: string;
  expectedAnswer?: string;
  setAnswerValues?: (
    id: string,
    answerType: number,
    value: string,
    referralId: string,
    questionType?: number,
    activityQuestionTestOptionId?: string
  ) => void;
  answeredQuestion?: models.Questions | undefined;

  readOnly?: boolean;
  isSolution?: boolean;
  isUserCollapse?: boolean;
  isHistoric?: boolean;
  isDisabled?: boolean;
  isNeededToRedo?: boolean;

  question?: models.Questions;
  setCommentValues?: (id: string, value: string, valueStatusQuestion: number, referralId: string) => void;
}

const DiscursiveQuestion: React.FC<IProps> = ({
  id,
  idUser,
  description,
  expectedAnswer,
  setAnswerValues,
  answeredQuestion,

  readOnly,
  isSolution,
  isUserCollapse,
  isHistoric,
  isDisabled,
  isNeededToRedo,

  question,
  setCommentValues,
}) => {
  const [fileUrl, setFileUrl] = useState<string>('');
  const [commentTutor, setCommentTutor] = useState('');
  const [valueStatusQuestion, setValueStatusQuestion] = useState<number>();

  const {
    auth: { me },
  } = useReduxState();

  const [disableReview, setDisableReview] = useState(isHistoric);

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const isTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  const idStudent = isTutor ? idUser : me?.id;

  const currentAnswer = answeredQuestion?.answers?.filter((item) => item?.user?.id === idStudent)?.[0];

  const answersFilterByUser = answeredQuestion?.answers?.filter((item) => item?.user?.id === idStudent);

  const setAnswer = useCallback(
    (answerType: number, value: string, referralId: string, activityQuestionTestOptionId?: string) => {
      setAnswerValues &&
        setAnswerValues(id, answerType, value, referralId, question?.questionType, activityQuestionTestOptionId);
    },
    [id, setAnswerValues]
  );

  const setComment = useCallback(
    (value: string, valueStatusQuestion: number, referralId: string) => {
      setCommentValues && setCommentValues(id, value, valueStatusQuestion, referralId);
    },
    [id, setCommentValues]
  );

  useEffect(() => {
    if (isHistoric) {
      switch (currentAnswer?.answerStatus) {
        case UserActionType.QUESTION_FINISHED:
          setValueStatusQuestion(REVIEW_STATUS_TYPE.YES);
          break;
        case UserActionType.QUESTION_NEED_TO_REDO:
          setValueStatusQuestion(REVIEW_STATUS_TYPE.ANOTHER_ANSWER);
          break;
        case UserActionType.QUESTION_REPROVED:
          setValueStatusQuestion(REVIEW_STATUS_TYPE.NO);
          break;
      }
    }
  }, [currentAnswer?.answerStatus, isHistoric]);

  useEffect(() => {
    if (valueStatusQuestion) {
      setComment(commentTutor, valueStatusQuestion, currentAnswer?.id ?? '');
    }
  }, [commentTutor, valueStatusQuestion]);

  const answeredFields = (testQuestionId?: string) => {
    const answersFilterByUserAndTest = answeredQuestion?.answers?.filter(
      (item) => item?.testOption?.id === testQuestionId && item?.user?.id === idStudent
    );

    const keyAnswerByUser = Number(answersFilterByUser?.length) - 1;
    const keyAnswerByUserAndTest = Number(answersFilterByUserAndTest?.length) - 1;

    if (answeredQuestion?.answers && answeredQuestion?.answers.length > 0) {
      if (question?.questionType === QUESTION_TYPE.DISCURSIVE_SUBITENS) {
        return {
          id: answersFilterByUserAndTest?.[keyAnswerByUserAndTest]?.id as string,
          answer: answersFilterByUserAndTest?.[keyAnswerByUserAndTest]?.answer as string,
          fileUrl: answersFilterByUserAndTest?.[keyAnswerByUserAndTest]?.fileUrl as string,
          createdAt:
            (answersFilterByUserAndTest?.[keyAnswerByUserAndTest]?.updatedAt as string) ??
            (answersFilterByUserAndTest?.[keyAnswerByUserAndTest]?.createdAt as string),
        };
      } else {
        return {
          id: answersFilterByUser?.[keyAnswerByUser]?.id as string,
          answer: answersFilterByUser?.[keyAnswerByUser]?.answer as string,
          fileUrl: answersFilterByUser?.[keyAnswerByUser]?.fileUrl as string,
          createdAt:
            (answersFilterByUser?.[keyAnswerByUser]?.updatedAt as string) ??
            (answersFilterByUser?.[keyAnswerByUser]?.createdAt as string),
        };
      }
    }
  };

  const reviewProps = {
    setDisableReview,
    isUserCollapse,
    isHistoric,
    statusValue: valueStatusQuestion,
    onChangeStatus: setValueStatusQuestion,
    commentValue: commentTutor,
    onChangeComment: setCommentTutor,
    disabled: disableReview,
  };

  const answerQuestionProps = {
    setAnswer,
    readOnly,
    isSolution,
    isUserCollapse,
    isHistoric,
    disabled: isDisabled,
    isNeededToRedo: isNeededToRedo,
  };

  return (
    <div className="final-question-container">
      <div className="final-question-container__description">
        <RenderHTML htmlText={description} />

        <RenderCondition condition={question?.questionType === QUESTION_TYPE.DISCURSIVE_SUBITENS}>
          {question?.testOptions?.map((subItem: models.TestOptionQuestion, index: number) => (
            <div key={index}>
              <RenderHTML htmlText={subItem.description} />
              <div className="final-question-container__answer">
                <AnswerQuestion
                  {...answerQuestionProps}
                  expectedAnswer={subItem.answer}
                  activityQuestionTestOptionId={subItem.id}
                  answeredFields={answeredFields(subItem?.id)}
                />
              </div>
              <div className="answer-question-container__review-tutor__radio-group">
                {(isUserCollapse || isHistoric) && (
                  <ReviewTutor
                    {...{ ...reviewProps, setComment }}
                    idSubItem={
                      answeredQuestion?.answers?.filter(
                        (item) => item?.testOption?.id === subItem?.id && item?.user?.id === idUser
                      )?.[0]?.id
                    }
                  />
                )}
              </div>
              {answeredQuestion?.answers
                ?.filter((item) => item?.testOption?.id === subItem?.id && item?.user?.id === idStudent)?.[0]
                ?.comments.map((comment) => (
                  <div key={comment.id} className="final-question-container__feedback">
                    <RenderCondition condition={!!comment.comment}>
                      <Feedback
                        description={comment.comment}
                        name={comment.user.name}
                        creatorId={comment.user.id}
                        thumbSrc={comment.user.profilePictureUrl}
                      />
                    </RenderCondition>
                  </div>
                ))}
            </div>
          ))}
        </RenderCondition>

        <RenderCondition condition={isFileDownloadType(question?.questionType) && !!question?.mediaUrl}>
          <div className="download-pdf">
            <a
              href={question?.mediaUrl || ''}
              target="_blank"
              download={question?.mediaUrl || ''}
              style={{ fontSize: '13px', fontWeight: 'normal' }}
            >
              <u>{translate('COMPONENTS.RENDER_CONTENT.DOWNLOAD_ARCHIVE')}</u>
            </a>
            <DownloadOutlined className="icon" />
          </div>
        </RenderCondition>

        <RenderCondition condition={isFileUploadType(question?.questionType)}>
          <div className="mt-4">
            {isTutor ? (
              <RenderCondition
                condition={question?.questionType !== QUESTION_TYPE.DISCURSIVE_AND_FILE_UPLOAD && !!expectedAnswer}
              >
                <div className="mt-4">
                  <span className="answer-question-container__review-tutor__expected-answer">
                    {translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.EXPECTED_ANSWER')}
                    <RenderHTML htmlText={expectedAnswer || ''} />
                  </span>
                </div>
              </RenderCondition>
            ) : (
              <DragAndDrop
                onChange={(value: string) => {
                  setFileUrl(value);
                  setAnswer(ANSWER_TABS.ATTACHMENTS, value, currentAnswer?.id ?? '');
                }}
                value={fileUrl || currentAnswer?.fileUrl || ''}
                disabled={!!currentAnswer?.fileUrl && !isNeededToRedo}
              />
            )}
          </div>
          {currentAnswer?.fileUrl && (
            <div className="download-pdf">
              <a
                href={currentAnswer?.fileUrl}
                target="_blank"
                download={currentAnswer?.fileUrl}
                style={{ fontSize: '13px', fontWeight: 'normal' }}
              >
                <u>{translate('COMPONENTS.RENDER_CONTENT.DOWNLOAD_ANWSER')}</u>
              </a>
              <DownloadOutlined className="icon" />
            </div>
          )}
          <div className="answer-question-container__review-tutor__radio-group">
            {(isUserCollapse || isHistoric) && question?.questionType !== QUESTION_TYPE.DISCURSIVE_AND_FILE_UPLOAD && (
              <ReviewTutor {...reviewProps} />
            )}
          </div>
        </RenderCondition>
      </div>

      <div className="final-question-container__answer">
        <RenderCondition condition={isDiscursiveQuestion(question?.questionType)}>
          <AnswerQuestion
            {...{
              ...answerQuestionProps,
              expectedAnswer,
            }}
            answeredFields={answeredFields()}
          />
          <div className="answer-question-container__review-tutor__radio-group">
            <RenderCondition condition={isUserCollapse || isHistoric}>
              <ReviewTutor {...reviewProps} />
            </RenderCondition>
          </div>
        </RenderCondition>
      </div>

      <RenderCondition condition={question?.questionType !== QUESTION_TYPE.DISCURSIVE_SUBITENS}>
        {currentAnswer?.comments?.map((comment) => (
          <div key={comment.id} className="final-question-container__feedback">
            <RenderCondition condition={!!comment.comment}>
              <Feedback
                description={comment.comment}
                name={comment.user.name}
                creatorId={comment.user.id}
                thumbSrc={comment.user.profilePictureUrl}
              />
            </RenderCondition>
          </div>
        ))}
      </RenderCondition>
    </div>
  );
};

export default DiscursiveQuestion;
