import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ContentActions } from '@portal/actions';
import { decreaseLoading, increaseLoading } from '@portal/actions/loading';

import { RenderPdf } from '@portal/components/v2';
import LockedContent from '@portal/components/v1/ContentComponents/LockedContent/LockedContent';
import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import IcAudio from '@portal/components/v1/Icon/IcAudio';

import { CONTENT_TYPE } from '@portal/enum/contentType';
import { useReduxState } from '@portal/hooks/useReduxState';
import { IRouteParams } from '@portal/models/route-params';
import { isBeforeBySchedule } from '@portal/utils/isAvailableBySchedule';
import { translate } from '@portal/services/i18n';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { getCookie } from '@portal/services/cookie';
import { RenderCondition } from '@portal/utils';
import { UserActionType } from '@portal/enum/userActionType';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const RenderContent: React.FC = () => {
  const { contentId } = useParams<IRouteParams>();

  const dispatch = useDispatch();

  const {
    content: { content, contentSchedule },
    sidebarContent: { isHide },
    auth: { me },
  } = useReduxState();

  const ref = useRef<HTMLAnchorElement | null>(null);
  const virtualGroupId = getCookie('virtual-group-id');

  const onLoadIframe = useCallback(
    (start: boolean) => {
      if (start) {
        dispatch(increaseLoading());
      } else {
        dispatch(decreaseLoading());
      }
    },
    [dispatch]
  );

  const isTutor = isDashTutor(me?.profileType);

  useEffect(() => {
    if (contentId) {
      dispatch(ContentActions.getContentById(contentId, true));
    }
  }, [contentId]);

  useEffect(() => {
    if (contentId && virtualGroupId) {
      dispatch(ContentActions.getContentScheduleById(contentId, virtualGroupId, true));
    }
  }, [contentId, virtualGroupId]);

  useEffect(() => {
    if (content?.type === CONTENT_TYPE.HTML && content?.fileUrl) {
      onLoadIframe(true);
    }
  }, [content, onLoadIframe]);

  const endAt = moment(contentSchedule?.endAt);

  const hasActivitiesToRedo = content?.activities?.some(
    (activity) => activity?.status === UserActionType.ACTIVITY_TO_REDO
  );

  const isBlockContent =
    (isBeforeBySchedule(contentSchedule?.startAt) ||
      (contentSchedule?.virtualGroup?.finalDateBlock && moment().isAfter(endAt))) &&
    !isTutor &&
    !hasActivitiesToRedo;

  return (
    <>
      {contentSchedule?.id && isBlockContent ? (
        <LockedContent schedule={contentSchedule} />
      ) : (
        <RenderCondition condition={!!content?.id}>
          <div className="render-content">
            {isTutor && (content?.materialForTutorUrl || content?.fileUrl) && (
              <div className="download-pdf">
                {content?.type === CONTENT_TYPE.AUDIO && !content?.materialForTutorUrl ? (
                  <u
                    onClick={() =>
                      dispatch(ContentActions.downloadContent(ref, content?.materialForTutorUrl || content?.fileUrl))
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {translate('COMPONENTS.RENDER_CONTENT.DOWNLOAD_AUDIO')}
                  </u>
                ) : (
                  <a href={content?.materialForTutorUrl || content?.fileUrl} download target="_blank">
                    <u>{translate('COMPONENTS.RENDER_CONTENT.DOWNLOAD_PDF')}</u>
                  </a>
                )}
                <a ref={ref} target="_blank" download={content?.title} />

                <DownloadOutlined className="icon" />
              </div>
            )}

            {content?.type === CONTENT_TYPE.PDF && <RenderPdf fileUrl={content?.fileUrl || ''} />}

            {content?.type === CONTENT_TYPE.AUDIO && (
              <div className="render-content__audio-render">
                {content?.description && (
                  <div
                    className={
                      isHide
                        ? 'render-content__audio-render__description--sidebar-hide'
                        : 'render-content__audio-render__description'
                    }
                  >
                    <RenderHTML htmlText={content?.description} />
                  </div>
                )}
                <div className="render-content__audio-render__container-card">
                  <div className="render-content__audio-render__card">
                    <div className="render-content__audio-render__icon">
                      <IcAudio width="110" height="150" />
                    </div>
                    <div className="render-content__audio-render__player">
                      <audio src={content?.fileUrl} controls={true} controlsList="nodownload" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {[CONTENT_TYPE.HTML, CONTENT_TYPE.EBOOK].includes(content?.type) && (
              <iframe
                className="render-content__html-render"
                title="lms content"
                src={content?.fileUrl}
                frameBorder="0"
                allowFullScreen
                onLoadStart={() => onLoadIframe(true)}
                onLoad={() => onLoadIframe(false)}
              />
            )}

            {content?.type === CONTENT_TYPE.TEXTAREA && (
              <div className="render-content__render-text">
                <RenderHTML htmlText={content?.description} />
              </div>
            )}
          </div>
        </RenderCondition>
      )}
    </>
  );
};

export default RenderContent;
