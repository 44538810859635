import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as AuthActions from '@portal/actions/auth';
import { COURSE_TYPE_LOGGED } from '@portal/actions/actionTypes';
import { getProductsUserJourney } from '@portal/actions/user';
import { BrandActions, HomeNotificationActions, UserActions } from '../../actions';

import { COURSE_TYPE } from '@portal/enum/courseType';
import { isEducator, ProfileType } from '@portal/enum/profileType';
import TopBottomDefaultTemplate from '@portal/hoc/TopBottomDefaultTemplate';
import { useReduxState } from '@portal/hooks/useReduxState';
import scrollToTop from '@portal/utils/scrollToTop';
import { setThemeBrand, setThemeProduct } from '@portal/utils/setTheme';
import { getStackPath } from '~/config/routes';

import Login from '@portal/pages/Auth/Login/Login';
import ChatNavigationStack from '@portal/pages/LMS/Chat/ChatNavigationStack';
import CourseDetailsNavigationStack from '@portal/pages/LMS/CourseDetails/CourseDetailsNavigationStack';
import CoursesNavigationStack from '@portal/pages/LMS/Courses/CoursesNavigationStack';
import HomeNavigationStack from '@portal/pages/LMS/Home/HomeNavigationStack';
import NotFound from '@portal/pages/LMS/NotFound/NotFound';
import ProfileNavigationStack from '@portal/pages/LMS/Profile/ProfileNavigationStack';
import RedirectNavigationStack from '@portal/pages/LMS/Redirect/RedirectNavigationStack';

import { getConfigPortal } from '@portal/actions/config';
import { getCookie } from '@portal/services/cookie';
import * as CookieService from '~/services/cookie';
import * as StorageService from '~/services/storage';
import ContentNavigationStack from './Content/ContentNavigationStack';
import GroupsNavigationStack from './Groups/GroupsNavigationStack';

import { Constants } from '@portal/config/env';

const LMSNavigationStack: React.FC = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const token = CookieService.getCookie('portal-token');
  const pathname = location.pathname.replace('/', '');
  const productId =
    pathname.includes('home') && pathname.split('/')[1]
      ? pathname.split('/')[1]
      : JSON.parse(localStorage.getItem('brandProductsConfig') as string)?.id;

  const {
    auth: { me, courseType },
    user: { products },
    brand: { brandProductConfig, brandConfig },
  } = useReduxState();

  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  // force scroll to top when change the route
  useEffect(() => {
    scrollToTop();
  }, [match]);

  useEffect(() => {
    if (me && !courseType) {
      let courseTypeResult = null;

      if (me.profileType === ProfileType.STUDENT) {
        courseTypeResult = COURSE_TYPE.ELECTIVE;
      } else if (isEducator(me.profileType)) {
        courseTypeResult = COURSE_TYPE.TRAIL;
      }
      if (courseTypeResult) {
        dispatch({
          type: COURSE_TYPE_LOGGED,
          payload: courseTypeResult,
        });
      }
    }
  }, [courseType, me]);

  useEffect(() => {
    if (productId && !brandProductConfig?.id) {
      dispatch(
        BrandActions.getBrandProductsConfig(productId, (config) => {
          setThemeProduct(config);
        })
      );
    }
  }, [productId]);

  useEffect(() => {
    dispatch(
      BrandActions.getProductsConfig((config) => {
        setThemeBrand(config);
      })
    );

    dispatch(getConfigPortal());

    dispatch(HomeNotificationActions.getAllNotifications({ limit: 30 }));
  }, []);

  useEffect(() => {
    if (!isSaePartnership) {
      CookieService.deleteCookie('user-integration', '/', Constants.brandCookieDomain);

      StorageService.removeItem('courseId-redirect');

      if (!products) {
        dispatch(UserActions.getProducts());
      }

      if (brandConfig?.id) {
        dispatch(getProductsUserJourney(brandConfig?.id));
      }
    }
  }, [me, isSaePartnership, brandConfig]);

  useEffect(() => {
    if (!token && !pathname?.includes('redirect')) {
      window.location.replace(`${Constants.portalUrl}/login`);
    }

    if (!pathname?.includes('redirect')) {
      dispatch(AuthActions.checkIsLogged(token));
    }
  }, [token, pathname]);

  return (
    <Container fluid className="p-0 lms-navigation-stack">
      <Switch>
        <Route path="/" exact>
          <Redirect to={`${getStackPath('HOME')}/:id?`} />
        </Route>

        <Route path={`${getStackPath('HOME')}/:id?`}>
          <TopBottomDefaultTemplate top banner navbar ComponentWrapped={<HomeNavigationStack />} />
        </Route>

        <Route path={getStackPath('COURSES')}>
          <TopBottomDefaultTemplate top banner navbar ComponentWrapped={<CoursesNavigationStack />} />
        </Route>

        <Route path={getStackPath('GROUPS')}>
          <TopBottomDefaultTemplate top banner navbar ComponentWrapped={<GroupsNavigationStack />} />
        </Route>

        <Route path={getStackPath('COURSE_DETAILS')}>
          <TopBottomDefaultTemplate top banner navbar ComponentWrapped={<CourseDetailsNavigationStack />} />
        </Route>

        <Route path={getStackPath('CHAT')}>
          <TopBottomDefaultTemplate top ComponentWrapped={<ChatNavigationStack />} />
        </Route>

        <Route path={getStackPath('CONTENT')}>
          <TopBottomDefaultTemplate top navbar ComponentWrapped={<ContentNavigationStack />} />
        </Route>

        <Route path={getStackPath('PROFILE')}>
          <ProfileNavigationStack />
        </Route>

        <Route path={getStackPath('REDIRECT')}>
          <RedirectNavigationStack />
        </Route>

        <Route path={getStackPath('REDIRECT_LMS')}>
          <RedirectNavigationStack />
        </Route>

        <Route path={getStackPath('AUTH')}>
          <Login />
        </Route>

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Container>
  );
};

export default LMSNavigationStack;
