import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

import { Dropdown, Input, Modal, Select, Table } from 'antd';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { GroupActions, UserActions } from '@portal/actions';

import { Btn, SearchBarUser } from '@portal/components';

import { useReduxState } from '@portal/hooks/useReduxState';
import { translate } from '@portal/services/i18n';
import EmptyBanner from '@portal/assets/png/homePage/empty_banner.png';
import { IcFilter } from '@portal/assets/Icon';
import SelectAsyncPaginate from '../AdvancedSelectPagination/SelectPagination';
import StudentClass from '@portal/repositories/studentClass';
import { getAllAcademicYears } from '@portal/actions/academicYearList';
import { RenderCondition } from '@portal/utils';
import { ICallbackOptions } from '@portal/types';
import { ProfileType } from '@portal/enum';

interface IProps {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddStudentsModal: React.FC<IProps> = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const {
    group,
    user,
    brand,
    auth: { me },
    academicYear: { academicYearList },
    loading: { localAmount },
  } = useReduxState();

  const [idsSelected, setIdsSelected] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [paginationStudents, setPaginationStudent] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const [filtersStudent, setFiltersStudents] = useState<{
    email?: string;
    classId?: string;
    yearList?: string;
  }>();

  const [filterStudentName, setFilterStudentName] = useState<string>('');

  const [search] = useDebounce(filterStudentName, 500);

  const groupInfos = group?.details;

  const yearsFilter = academicYearList?.filter((item) => {
    const academicYearsArray = brand?.brandProductConfig?.academicYearList?.split(',');

    return academicYearsArray?.includes(item?.key?.toString() as string);
  });

  const Option = Select;

  const onClose = () => {
    closeModal(false);
    setIdsSelected([]);
  };

  const callBackAddStudent = () => {
    dispatch(
      GroupActions.getStudent(params.id, {
        limit: paginationStudents?.limit,
        offset: paginationStudents?.offset,
        schoolId: me?.idSchool,
        orderBy: 'name',
      })
    );
    onClose();
  };

  const getStudentsList = (options?: ICallbackOptions<undefined>) => {
    dispatch(
      UserActions.getUserReport(
        {
          limit: paginationStudents?.limit,
          offset: paginationStudents?.offset,
          profileType: ProfileType.STUDENT,

          notInVirtualGroupId: params.id,
          schoolId: group?.details?.schoolId,
          orderBy: 'name',
          ...(search && { name: search }),
          ...(filtersStudent?.email && { email: filtersStudent?.email }),
          ...(filtersStudent?.classId && { classId: filtersStudent?.classId }),
          ...(filtersStudent?.yearList?.length
            ? { yearList: filtersStudent?.yearList?.toString() }
            : { yearList: brand?.brandProductConfig?.academicYearList }),
        },
        true
      )
    );

    options?.onSuccess?.();
  };

  const clearFilters = () => {
    setFiltersStudents({});
    setPaginationStudent((state) => ({ ...state, offset: 0 }));
    handleDropdownVisibleChange(false);
  };

  useEffect(() => {
    if (openModal) {
      getStudentsList();
    }
  }, [openModal, paginationStudents]);

  useEffect(() => {
    setPaginationStudent((state) => ({ ...state, offset: 0 }));
  }, [search]);

  useEffect(() => {
    if (showFilter) {
      dispatch(getAllAcademicYears());
    }
  }, [showFilter]);

  const handleAddStudents = () => {
    dispatch(GroupActions.addStudents(params.id, idsSelected, callBackAddStudent));
  };

  const studentSelected = (value: React.Key[]) => {
    setIdsSelected([...(value as string[])]);
  };

  const columns = [
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.COLUMN_TABLE.NAME'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.COLUMN_TABLE.EMAIL'),
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const handleDropdownVisibleChange = (flag: boolean) => {
    setShowFilter(flag);
  };

  const filters = (
    <>
      <div className="mb-4 w-100 d-flex">
        <div className="mr-4 w-50">
          <Input
            size="large"
            style={{ height: '2.7rem' }}
            value={filtersStudent?.email}
            placeholder={translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.FILTERS.EMAIL')}
            onChange={(e) => setFiltersStudents({ ...filtersStudent, email: e.target.value })}
          />
        </div>

        <div className="w-50">
          <Select
            mode="multiple"
            size="large"
            value={filtersStudent?.yearList}
            style={{ width: '100%', height: '2.7rem' }}
            placeholder={translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.FILTERS.YEARS')}
            onChange={(e) =>
              setFiltersStudents({
                ...filtersStudent,
                yearList: e,
              })
            }
            maxTagCount={2}
          >
            {yearsFilter?.map((item) => (
              <Option value={item.key}>{item.name}</Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-between">
        <div className="w-50 mr-4">
          <SelectAsyncPaginate
            filters={{
              yearList: brand?.brandProductConfig?.academicYearList,
              idSchool: group?.details?.schoolId,
              orderBy: 'year',
              select: 'name,id,year,segment',
            }}
            placeholder={translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.FILTERS.CLASSE')}
            valueFilter={filtersStudent?.classId}
            asyncFunction={StudentClass.getAllStudentClasses}
            onChange={(e: { value: string }) =>
              setFiltersStudents({
                ...filtersStudent,
                classId: e.value,
              })
            }
          />
        </div>

        <div className="w-50 d-flex justify-content-end">
          <div className="d-flex align-items-center flex-column">
            <Btn
              variant="primary"
              onClick={() => {
                setPaginationStudent((state) => ({ ...state, offset: 0 }));
                handleDropdownVisibleChange(false);
              }}
            >
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.BUTTON.FILTER')}
            </Btn>
            <RenderCondition
              condition={!!filtersStudent?.email || !!filtersStudent?.classId || !!filtersStudent?.yearList}
            >
              <span style={{ cursor: 'pointer', color: '#7F8182' }} onClick={clearFilters}>
                {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.BUTTON.CLEAR_FILTERS')}
              </span>
            </RenderCondition>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      open={openModal}
      onCancel={onClose}
      bodyStyle={{ padding: '0 0 2rem 0' }}
      footer={null}
      style={{ top: 20 }}
      width={750}
    >
      <div>
        <Row>
          <img
            src={groupInfos?.course?.photoUrl || EmptyBanner}
            alt="empty banner"
            width="100%"
            className="groups-page__banner-modal"
          />
        </Row>

        <Col className="modal-close-group__spacing mt-4 pl-5 pt-2">
          <Row>
            <span className="modal-close-group__title__bold">
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.TITLE')}
            </span>
          </Row>

          <Row className="pt-5">
            <span className="modal-close-group__subtitle">
              <span className="modal-close-group__subtitle__bold">
                {translate('PAGES.GROUPS.GROUPS_PAGE.SUBTITLE')}
              </span>
              {groupInfos?.name}
            </span>
          </Row>
        </Col>
      </div>

      <div className="pl-5 pr-5 mt-4">
        <Row className="mb-2 align-items-center w-75">
          <div className="mr-3">
            <strong>Pesquisar aluno:</strong>
          </div>

          <SearchBarUser handleChange={(value) => setFilterStudentName(value)} placeholder="Digite o nome do aluno" />

          <div className="ml-3" onScroll={() => setShowFilter(false)}>
            <Dropdown
              overlayStyle={{
                backgroundColor: '#F6F6F6',
                padding: '2rem',
                borderRadius: '8px',
                width: '40rem',
              }}
              overlay={filters}
              placement="bottomCenter"
              trigger={['click']}
              visible={showFilter}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <div className="filter__icon">
                <IcFilter />
              </div>
            </Dropdown>
          </div>
        </Row>
        <Table
          loading={localAmount === 1}
          rowKey={(data) => data.id}
          rowSelection={{
            onChange: (rowsSelect) => {
              studentSelected(rowsSelect);
            },
            selectedRowKeys: idsSelected,
            preserveSelectedRowKeys: true,
          }}
          columns={columns}
          dataSource={user?.list}
          pagination={{
            onChange: (e) => {
              {
                setPaginationStudent((state) => ({
                  ...state,
                  offset: e - 1,
                }));
              }
            },
            current: paginationStudents.offset + 1,
            total: user?.listCount,
            defaultCurrent: 1,
            showSizeChanger: false,
          }}
        />
      </div>

      <Row className="pt-5">
        <Col className="modal-close-group__buttons">
          <Row className="modal-close-group__gap btn-responsive mr-5 mt-2 mb-4">
            <Btn variant="primary" onClick={() => handleAddStudents()} disabled={idsSelected.length === 0}>
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.BUTTON.ADD')}
            </Btn>
            <Btn variant="secondary" onClick={onClose}>
              {translate('PAGES.GROUPS.GROUPS_PAGE.MODAL_ADD.BUTTON.CANCEL')}
            </Btn>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddStudentsModal;
