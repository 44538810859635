import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Col, Row } from 'react-bootstrap';

import { CourseActions, SatisfactionSurveyActions } from '@portal/actions';
import { SATISFACTION_SURVEY_COURSE_ID, SHOW_MODAL_SATISFACTION_SURVEY } from '@portal/actions/actionTypes';
import { downloadCertificate } from '@portal/actions/certificate';
import * as MessageService from '~/services/message';

import { Btn } from '@portal/components';

import { Constants } from '@portal/config/env';
import { getStackPath } from '@portal/config/routes';
import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import { SatisfactionSurveyStatus } from '@portal/enum/satisfactionSurvey';
import { ILmsTrail } from '@portal/models/module';
import { Course } from '@portal/models/reducers';
import { deleteCookie, setCookie } from '@portal/services/cookie';
import { translate } from '@portal/services/i18n';
import { RenderCondition } from '@portal/utils';
import * as StorageService from '~/services/storage';

interface IProps {
  cardData: Course;
  electiveNotSubscribed?: boolean;
  allowStudentEnrollment?: boolean;
  isStarted?: boolean;
  isConcluded?: boolean;
  isInFocus?: boolean;
  trail?: ILmsTrail;
  isDisabledSeeDetails?: boolean;
}

const Card: React.FC<IProps> = ({
  cardData,
  electiveNotSubscribed = false,
  isStarted,
  isConcluded,
  isInFocus,
  trail,
  isDisabledSeeDetails,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<HTMLAnchorElement | null>(null);

  const { id, title, photoUrl, description, virtualGroup } = cardData;
  const stripedHtml = description?.replace(/<[^>]+>/g, '');

  const getBrandProduct = StorageService.getItem('brandProductsConfig') || '';

  const handleAccessContent = () => {
    if (electiveNotSubscribed) {
      dispatch(
        CourseActions.subscribeUserOnCourse(id, getBrandProduct?.id, {
          onSuccess: () => {
            history.push(`/redirecting/${id}`);
          },
        })
      );
    } else {
      history.push(`/content/${id}`);
    }
    setCookie('virtual-group-id', virtualGroup?.[0]?.id, Constants.brandCookieDomain as string);
  };

  const handleRedirectToCourseDetails = useCallback(
    (courseId: string) => {
      deleteCookie('virtual-group-id', '/', Constants.brandCookieDomain);

      if (courseId) {
        history.push(`${getStackPath('COURSE_DETAILS')}/${courseId}`);
      }
    },
    [history]
  );

  const isCompletedAtLeastOneModule = !!cardData?.courseUser?.[0]?.completedAtLeastOneModule;

  const handleDownloadCertificate = async () => {
    if (!isCompletedAtLeastOneModule) {
      MessageService.error('COMPONENTS.CARD.MESSAGES.WITHOUT_PROGRESS');
      return;
    }

    if (
      [SatisfactionSurveyStatus.PENDING, SatisfactionSurveyStatus.BLOCKED]?.includes(
        Number(cardData?.courseUser?.[0]?.satisfactionSurveyStatus)
      ) &&
      cardData?.satisfactionSurveyId
    ) {
      dispatch(SatisfactionSurveyActions.getSatisfactionSurvey(cardData?.satisfactionSurveyId, () => {}, true));
      dispatch({
        payload: true,
        type: SHOW_MODAL_SATISFACTION_SURVEY,
      });
      dispatch({
        payload: cardData?.id,
        type: SATISFACTION_SURVEY_COURSE_ID,
      });

      return;
    }

    dispatch(downloadCertificate(id, ref));
  };

  return (
    <div
      className="card-container"
      {...(isInFocus && {
        style: { zIndex: 99999, backgroundColor: 'white', position: 'relative' },
      })}
    >
      <RenderCondition condition={!!trail}>
        <div
          style={{
            position: 'absolute',
            padding: '0.5rem',
            backgroundColor: getBrandProduct?.primaryColor,
            width: '100%',
            borderRadius: '10px 10px 0 0',
            color: 'white',
            fontWeight: 700,
            fontSize: '14px',
          }}
        >
          {trail?.name}
        </div>
      </RenderCondition>
      <div className="card-container__image-card">
        <div className="card-container__background-image__title">
          <span>{title}</span>
        </div>

        <img src={photoUrl} alt="background" className="card-container__background-image" />
      </div>
      <div>
        <Row>
          <Col className="card-container__description">
            <div className="card-container__description__content">{stripedHtml}</div>
          </Col>
        </Row>
        <div className={`row ${isStarted ? 'flex-row-reverse' : 'flex-row'}`}>
          <Col>
            <RenderCondition condition={isStarted}>
              <Btn
                title={translate('COMPONENTS.CARD.BUTTONS.CONTINUE')}
                onClick={handleAccessContent}
                size={BUTTON_SIZE.SMALL}
              />
            </RenderCondition>
          </Col>

          <Col>
            <RenderCondition condition={isConcluded}>
              <Btn
                title={translate('COMPONENTS.CARD.BUTTONS.CERTIFICATION')}
                onClick={handleDownloadCertificate}
                size={BUTTON_SIZE.MEDIUM}
                variant={!isCompletedAtLeastOneModule ? 'pseudo-disabled' : 'primary'}
              />
            </RenderCondition>
          </Col>
          <a ref={ref} target="_blank" download={`Certificado - ${title}`} />

          <RenderCondition condition={!isConcluded && !isDisabledSeeDetails && !isStarted}>
            <Col>
              <Btn
                title={translate('COMPONENTS.CARD.BUTTONS.SEE_DETAILS')}
                onClick={() => handleRedirectToCourseDetails(id)}
                size={BUTTON_SIZE.SMALL}
                variant={isStarted || isConcluded ? 'secondary' : 'primary'}
              />
            </Col>
          </RenderCondition>

          <RenderCondition condition={isDisabledSeeDetails}>
            <Col>
              <Btn
                title={translate('COMPONENTS.CARD.BUTTONS.CONTINUE')}
                onClick={handleAccessContent}
                size={BUTTON_SIZE.SMALL}
              />
            </Col>
          </RenderCondition>
        </div>
      </div>
    </div>
  );
};

export default Card;
