import { Collapse } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { ActivityActions, CourseActions, UserActions, UserCommentActions } from '@portal/actions';

import Btn from '@portal/components/v1/Btn/Btn';
import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';
import CollapseUser from '@portal/components/v1/UI/Molecules/CollapseUser/CollapseUser';
import AdvancedCollapse from '@portal/components/v1/UI/Atoms/AdvancedCollapse/AdvancedCollapse';
import ObjectiveQuestion from '@portal/components/v1/Question/ObjectiveQuestion/ObjectiveQuestion';
import StatusDeadline from '@portal/components/v1/ContentComponents/StatusDeadline/StatusDeadline';
import DiscursiveQuestion from '@portal/components/v1/Question/DiscursiveQuestion/DiscursiveQuestion';

import { translate } from '@portal/services/i18n';
import * as StorageService from '~/services/storage';
import { IRouteParams } from '@portal/models/route-params';
import { useReduxState } from '@portal/hooks/useReduxState';
import { isObjectiveQuestion } from '@portal/utils/isObjectiveQuestion';

import { ANSWER_TABS } from '@portal/enum/answerTabs';
import { TasksStatus } from '@portal/enum/taskStatus';
import { UserActionType } from '@portal/enum/userActionType';
import { REVIEW_TUTOR_TYPE } from '@portal/enum/reviewTutorType';
import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import { isEducatorStudent } from '@portal/utils/isEducatorStudent';
import { ProductType } from '@portal/enum/productType';
import { RenderCondition } from '@portal/utils';
import { CircularProgress } from '@mui/material';
import { getCookie } from '@portal/services/cookie';

interface IQuestionAnswers {
  questionId: string;
  answersSelected: string[];
  rightOptionsNumber: number;
  hasPendingAnswer: boolean;
}

const ActivitySectionTutor: React.FC = () => {
  const { Panel } = Collapse;

  const dispatch = useDispatch();
  const { activityId, courseId } = useParams<IRouteParams>();

  const [collapseSection, setCollapseSection] = useState<string[]>(['0']);
  const [idUser, setIdUser] = useState<string>('');
  const [answers, setAnswers] = useState<models.Answers[]>([]);
  const [comments, setComments] = useState<models.UserComment[]>([]);
  const [questionAnswers, setQuestionAnswers] = useState<IQuestionAnswers[]>([]);

  const {
    auth: { me },
    activity: { activityDetails, activityAnswers },
    content: { content },
    course: { courseProgress },
    loading: { localAmount },
    user: { userActivities, userActivitiesHistory, isLoading },
  } = useReduxState();

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';
  const virtualGroupId = getCookie('virtual-group-id');

  useEffect(() => {
    if (activityId) {
      dispatch(ActivityActions.getActivityDetails(activityId, true));

      setCollapseSection(['0']);
    }

    if (me?.idSchool && activityId && virtualGroupId) {
      dispatch(
        UserActions.getUsersActivities(activityId, virtualGroupId, {
          userActionType: UserActionType.PENDING_FOR_REVIEW,
          schoolId: me?.idSchool,
        })
      );
    }
  }, [activityId, me, virtualGroupId]);

  useEffect(() => {
    if (collapseSection.includes(REVIEW_TUTOR_TYPE.HISTORIC) && activityId && virtualGroupId) {
      dispatch(
        UserActions.getUsersActivitiesHistory(activityId, virtualGroupId, {
          schoolId: me?.idSchool,
        })
      );
    }
  }, [collapseSection, virtualGroupId]);

  useEffect(() => {
    if (activityDetails.id && idUser) {
      dispatch(
        ActivityActions.getAnsweredQuestions(
          activityDetails.id,
          () => {},
          {
            schoolId: me?.idSchool,
            userId: idUser,
          },
          true
        )
      );

      setComments([]);
    }
  }, [activityDetails, userActivities, idUser]);

  const setSelectedOptions = useCallback(
    (items: string[], questionId: string) => {
      const result = questionAnswers.map((question) => {
        if (question.questionId === questionId) {
          if (items.length <= question.rightOptionsNumber) {
            if (items.length === question.rightOptionsNumber) {
              return {
                ...question,
                answersSelected: items,
                hasPendingAnswer: false,
              };
            }

            return { ...question, answersSelected: items };
          } else {
            items.shift();

            return { ...question, answersSelected: items };
          }
        }

        return question;
      });

      setQuestionAnswers(result);
    },
    [questionAnswers]
  );

  const setAnswerValues = useCallback((id: string, answerType: number, value: string) => {
    setAnswers((prevAnswers) => {
      return prevAnswers.map((prevAnswer) => {
        if (prevAnswer.activityQuestionId === id) {
          switch (answerType) {
            case ANSWER_TABS.TEXT:
              return {
                ...prevAnswer,
                answer: { ...prevAnswer.answer, value, showAlert: false },
              };
            case ANSWER_TABS.ATTACHMENTS:
              return {
                ...prevAnswer,
                fileUrl: { ...prevAnswer.fileUrl, value, showAlert: false },
              };
          }

          return prevAnswer;
        }

        return prevAnswer;
      });
    });
  }, []);

  const setCommentValues = (id: string, value: string, valueStatusQuestion: number, referralId: string) => {
    const idExist = comments.find((comment) => {
      return comment.referralId === referralId;
    });
    const index = comments.indexOf(idExist as models.UserComment);

    if (valueStatusQuestion) {
      if (index !== -1) {
        comments[index] = {
          type: 1,
          comment: value,
          referralId,
          activityQuestionId: id,
          teacherResponseType: valueStatusQuestion,
        };
      } else {
        setComments([
          ...comments,
          {
            type: 1,
            comment: value,
            referralId,
            activityQuestionId: id,
            teacherResponseType: valueStatusQuestion,
          },
        ]);
      }
    }
  };

  const checkIfContains = useCallback((status: number, listToCheck: number[]) => {
    return listToCheck.includes(status);
  }, []);

  const getAnsweredQuestiion = useCallback((idQuestion: string, answeredQuestionList: models.Questions[] = []) => {
    return answeredQuestionList.find((questionAnswered) => {
      return questionAnswered.id === idQuestion;
    });
  }, []);

  const handleReviewSubmit = async (userComment: models.UserComment[], userId: string) => {
    dispatch(
      UserCommentActions.SendUserComment(
        userComment.filter((item) => item?.referralId?.length > 0),
        { activityId, userId },
        false,
        () => {
          dispatch(
            UserActions.getUsersActivities(activityId, virtualGroupId, {
              userActionType: UserActionType.PENDING_FOR_REVIEW,
              schoolId: me?.idSchool,
            })
          ),
            dispatch(
              UserActions.getUsersActivitiesHistory(activityId, virtualGroupId, {
                schoolId: me?.idSchool,
              })
            ),
            setComments([]);
          dispatch(
            ActivityActions.getAnsweredQuestions(
              activityDetails.id,
              () => {},
              {
                schoolId: me?.idSchool,
                userId: idUser,
              },
              true
            )
          );
          dispatch(
            CourseActions.getCourseProgress({
              idCourse: courseId,
              isTutor: true,
              isLocalLoading: true,
            })
          );
        }
      )
    );
  };

  return (
    <main className="activity-section">
      <div className="activity-section__status-deadline">
        {activityAnswers?.questions?.[0]?.answers?.[0]?.createdAt ? (
          <StatusDeadline
            isDone
            label={translate('PAGES.CONTENT.ACTIVITY_SECTION.LABEL_STATUS_CURRENT_ACTIVITY', {
              unit: sessionStorage.getItem('unitCurrent'),
              activity: activityDetails?.title || 'Atividade',
            })}
            doneDate={
              isEducatorStudent(me?.profileType) && productType === ProductType.LMS_EDUCATOR
                ? activityAnswers?.questions[0].answers[0].createdAt
                : undefined
            }
          />
        ) : (
          content?.schedule?.[0]?.endAt && (
            <StatusDeadline
              isDone={false}
              label={translate('PAGES.CONTENT.ACTIVITY_SECTION.LABEL_STATUS_CURRENT_ACTIVITY', {
                unit: sessionStorage.getItem('unitCurrent'),
                activity: activityDetails?.title || 'Atividade',
              })}
              statusDeadlienDate={
                isEducatorStudent(me?.profileType) && productType === ProductType.LMS_EDUCATOR
                  ? content?.schedule[0]?.endAt
                  : undefined
              }
            />
          )
        )}
      </div>

      <AdvancedCollapse
        onChange={(key: Array<string>) => {
          setCollapseSection([key[key?.length - 1]]), setIdUser('');
        }}
        keyActive={collapseSection}
        bordered={false}
      >
        <Panel
          className="collapse-container__panel"
          header={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.SOLUTION')}
          key={REVIEW_TUTOR_TYPE.SOLUTION}
        >
          <div className="activity-section__main-content">
            <h1 className="activity-section__main-title">{activityDetails?.title}</h1>
            <div className="activity-section__main-description">
              <RenderHTML htmlText={activityDetails?.description} />
            </div>

            {activityDetails?.questions?.map((question, index) => (
              <>
                {isObjectiveQuestion(question.questionType) ? (
                  <ObjectiveQuestion
                    setCommentValues={setCommentValues}
                    key={question.id}
                    index={index + 1}
                    description={question.description}
                    options={question.testOptions}
                    setSelectedOptions={(items: string[]) => setSelectedOptions(items, question.id)}
                    disabled={
                      checkIfContains(courseProgress?.test?.testStatus, [
                        TasksStatus.NOT_AVAILABLE,
                        TasksStatus.WAITING_REVIEW,
                        TasksStatus.DONE,
                      ]) || !!(courseProgress?.courseUser?.length > 0 && courseProgress.courseUser[0].isDone)
                    }
                    showCorectQuestions
                    isTemplateQuestion
                    isSolution
                    question={question}
                    idUser={idUser}
                  />
                ) : (
                  <DiscursiveQuestion
                    key={question.id}
                    id={question.id}
                    description={question.description}
                    setAnswerValues={setAnswerValues}
                    setCommentValues={setCommentValues}
                    answeredQuestion={getAnsweredQuestiion(question.id, activityAnswers?.questions)}
                    readOnly={!!(courseProgress?.courseUser?.length > 0 && courseProgress.courseUser[0].isDone)}
                    isSolution
                    expectedAnswer={question.answer}
                    question={question}
                    idUser={idUser}
                  />
                )}
              </>
            ))}
          </div>
        </Panel>

        {!!virtualGroupId && (
          <>
            <Panel
              className="activity-section__collapse-container__panel"
              header={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.REVIEW')}
              key={REVIEW_TUTOR_TYPE.PENDING_REVIEW}
              extra={
                <div className="activity-section__collapse-container__panel__extra__notification">
                  {userActivities?.usersActivities?.length}
                </div>
              }
            >
              <CollapseUser data={userActivities?.usersActivities} setIdUser={setIdUser} idUser={idUser}>
                <RenderCondition condition={localAmount !== 1}>
                  {activityAnswers?.questions?.map((question, index) => (
                    <>
                      {isObjectiveQuestion(question.questionType) ? (
                        <ObjectiveQuestion
                          setCommentValues={setCommentValues}
                          key={question.id}
                          index={index + 1}
                          description={question.description}
                          options={question.testOptions}
                          disabled
                          showCorectQuestions
                          isUserCollapse
                          question={question}
                          idUser={idUser}
                        />
                      ) : (
                        <DiscursiveQuestion
                          setCommentValues={setCommentValues}
                          key={question.id}
                          id={question.id}
                          description={question.description}
                          setAnswerValues={setAnswerValues}
                          answeredQuestion={getAnsweredQuestiion(question.id, activityAnswers?.questions)}
                          isUserCollapse
                          expectedAnswer={question.answer}
                          idUser={idUser}
                          question={question}
                        />
                      )}
                    </>
                  ))}
                  <div className="mt-5 mb-3">
                    <Btn
                      onClick={() =>
                        handleReviewSubmit(
                          (comments.length > 0 && comments) || StorageService.getItem('commentsTutor'),
                          idUser
                        )
                      }
                      disabled={!comments?.length}
                      title={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.SEND_COMMENTS')}
                      size={BUTTON_SIZE.FULL}
                    />
                  </div>
                </RenderCondition>
                <RenderCondition condition={localAmount === 1}>
                  <div className="activity-section__collapse-container__panel__loading__inner">
                    <CircularProgress color="primary" size={50} />
                  </div>
                </RenderCondition>
              </CollapseUser>
            </Panel>

            <Panel
              className="collapse-container__panel"
              header={translate('PAGES.CONTENT.ACTIVITY_SECTION.TUTOR_SECTION.HISTORY_REVIEW')}
              key={REVIEW_TUTOR_TYPE.HISTORIC}
            >
              <RenderCondition condition={!isLoading}>
                <CollapseUser
                  data={userActivitiesHistory?.usersActivitiesAnswers}
                  setIdUser={setIdUser}
                  idUser={idUser}
                >
                  <RenderCondition condition={localAmount !== 1}>
                    {activityAnswers?.questions?.length &&
                      activityAnswers?.questions?.map((question, index) => (
                        <>
                          {isObjectiveQuestion(question.questionType) ? (
                            <ObjectiveQuestion
                              key={question.id}
                              index={index + 1}
                              description={question.description}
                              options={question.testOptions}
                              disabled
                              showCorectQuestions
                              answeredQuestion={getAnsweredQuestiion(question.id, activityAnswers?.questions)}
                              isUserCollapse
                              isHistoric
                              idUser={idUser}
                              question={question}
                            />
                          ) : (
                            <DiscursiveQuestion
                              key={question.id}
                              id={question.id}
                              description={question.description}
                              answeredQuestion={getAnsweredQuestiion(question.id, activityAnswers?.questions)}
                              isUserCollapse
                              isHistoric
                              idUser={idUser}
                              question={question}
                              expectedAnswer={question.answer}
                            />
                          )}
                        </>
                      ))}
                  </RenderCondition>
                  <RenderCondition condition={localAmount === 1}>
                    <div className="activity-section__collapse-container__panel__loading__inner">
                      <CircularProgress color="primary" size={50} />
                    </div>
                  </RenderCondition>
                </CollapseUser>
              </RenderCondition>
              <RenderCondition condition={isLoading}>
                <div className="activity-section__collapse-container__panel__loading__inner">
                  <CircularProgress color="primary" size={50} />
                </div>
              </RenderCondition>
            </Panel>
          </>
        )}
      </AdvancedCollapse>
    </main>
  );
};

export default ActivitySectionTutor;
