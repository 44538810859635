import React from 'react';

import { Helmet } from 'react-helmet-async';

export const CertificatePageTitle = ({
  pageTitle = 'Certificado de Conclusão',
  children,
}: {
  pageTitle?: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {children}
    </>
  );
};
