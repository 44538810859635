import { getInstance } from './instance';
const MODULE_BASE_URL = '/brand';
const MODULE_BRAND_URL = '/brand-product';
const MODULE_PLAYLIST_URL = 'brand-product-playlist';

const brandAPI = {
  getProductsConfig: async () => {
    const instance = getInstance();
    const { data } = await instance.get(`${MODULE_BASE_URL}/products`);

    return data;
  },

  getBrandProductsConfig: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`${MODULE_BRAND_URL}/${id}`);

    return data;
  },

  getPlaylist: async (id: string) => {
    const instance = getInstance();
    const { data } = await instance.get(`${MODULE_PLAYLIST_URL}/${id}/all`);

    return data;
  },

  getBrandConfig: async () => {
    const instance = getInstance();
    const { data } = await instance.get(`${MODULE_BASE_URL}/config`);

    return data;
  },
};

export default brandAPI;
