import React from 'react';

import { RenderCondition } from '@portal/utils';
import { Flex, IcPageLoading } from '@portal/components/v2';

interface ICustomLoading {
  loading: boolean;
  isWhite?: boolean;
  brandPrimaryColor?: string;
}

const PageLoading: React.FC<ICustomLoading> = ({ brandPrimaryColor, loading, isWhite }) => {
  const pageLoadingProps: React.CSSProperties = {
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
  };

  return (
    <RenderCondition condition={loading}>
      <Flex data-testid="page-loading" style={pageLoadingProps}>
        <IcPageLoading width="5rem" height="5rem" color={isWhite ? '#FFF' : brandPrimaryColor} />
      </Flex>
    </RenderCondition>
  );
};

export default PageLoading;
