import React, { useCallback, useState } from 'react';

import { Radio, RadioChangeEvent, Space } from 'antd';

import { RenderCondition } from '@portal/utils';
import { useReduxState } from '@portal/hooks/useReduxState';
import { TasksStatus } from '@portal/enum/taskStatus';
import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';

interface ICourseTestObjectiveQuestion {
  options: models.TestOptionQuestionDone[];
  description?: string;
  showCorectQuestions?: boolean;
  setSelectedOptions?: (value: string[]) => void;
  question?: any;
  isDisabled?: boolean;
}

const CourseTestObjectiveQuestion: React.FC<ICourseTestObjectiveQuestion> = ({
  options,
  description,
  showCorectQuestions,
  setSelectedOptions,
  question,
  isDisabled,
}) => {
  const [checkedValue, setCheckedValue] = useState<string>('');

  const {
    courseTest: { courseTestDone },
    course: { courseProgress },
  } = useReduxState();

  const currentAnswer = () => {
    const answers = courseTestDone?.questions?.find((item) => item.id === question?.id)?.answers;

    if (courseProgress?.test?.testStatus === TasksStatus.DONE) {
      return answers?.[answers?.length - 1];
    }

    if (question?.answers?.length > 0) {
      return question?.answers?.[question?.answers?.length - 1];
    }

    return '';
  };

  const onRadioOptionChange = useCallback(
    (value: RadioChangeEvent) => {
      setCheckedValue(value.target.value);
      if (setSelectedOptions && value.target.value) {
        setSelectedOptions([value.target.value as string]);
      }
    },
    [setSelectedOptions]
  );

  const colorOption = (option: models.TestOptionQuestionDone) => {
    if (showCorectQuestions && option?.isCorrect) {
      return '#27AE60';
    }

    if (currentAnswer()?.answer && option.id === currentAnswer()?.answer && option?.isCorrect) {
      return '#27AE60';
    }

    if (currentAnswer()?.answer && option.id === currentAnswer()?.answer && !option?.isCorrect) {
      return '#EB5757';
    }

    return '#D3D3D3';
  };

  const borderSize = (option: models.TestOptionQuestionDone) => {
    if (showCorectQuestions && option?.isCorrect) {
      return '3px';
    }

    if (option.id === currentAnswer()?.answer || option.id === checkedValue) {
      return '3px';
    }

    return '0.5px';
  };

  return (
    <>
      <div className="question-test-container">
        <div className="question-test-container__title">
          <RenderCondition condition={!!description}>
            <div className="question-test-container__title__label">
              <RenderHTML htmlText={description as string} />
            </div>
          </RenderCondition>
        </div>

        <div className="question-test-container__options">
          <Radio.Group
            style={{ width: '100%' }}
            onChange={onRadioOptionChange}
            value={currentAnswer()?.answer || checkedValue}
            disabled={courseProgress?.test?.testStatus === TasksStatus.DONE || isDisabled}
          >
            {options?.map((option, optionIndex) => (
              <div
                key={option.id}
                className="question-test-container__options__content"
                style={{
                  border: `${borderSize(option)} solid ${colorOption(option)}`,
                }}
              >
                <Space direction="vertical">
                  <Radio value={option.id}>
                    <span className="question-test-container__options__label__option">
                      {String.fromCharCode(optionIndex + 65)}
                      {') '}
                    </span>
                    <span className="question-test-container__options__description">{option.description}</span>
                  </Radio>
                </Space>
              </div>
            ))}
          </Radio.Group>
        </div>
      </div>
    </>
  );
};

export default CourseTestObjectiveQuestion;
