import { Dispatch } from 'react';

import { decreaseLoading, increaseLoading } from '../loading';

import certificateAPI from '@portal/repositories/certificate';
import { ICallbackOptions, IUserCertificateInfos } from '@portal/types';

export const certificateActions = {
  getUserCertificateInfos:
    (courseUserId: string, idSchool: string, options?: ICallbackOptions<IUserCertificateInfos>) =>
    async (dispatch: Dispatch<any>) => {
      dispatch(increaseLoading());

      try {
        const response = await certificateAPI.getUserCertificateInfos(courseUserId, idSchool);

        options?.onSuccess?.(response);
      } catch {
        options?.onError?.();
      } finally {
        dispatch(decreaseLoading());
      }
    },
};
