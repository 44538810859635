import { Dispatch } from 'redux';

import BannerRequests from '@portal/repositories/banner';
import * as MessageService from '~/services/message';

import { HOME_BANNERS } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getHomeBanners =
  (searchParameters: advancedFilterModels.BannerFilter, isLocalLoading?: boolean) => async (dispatch: Dispatch) => {
    dispatch(increaseLoading(!!isLocalLoading));
    try {
      const payload = await BannerRequests.getHomeBanners(searchParameters);
      dispatch({
        payload,
        type: HOME_BANNERS,
      });
    } catch (err) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading(!!isLocalLoading));
    }
  };
