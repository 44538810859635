import React from 'react';

import { AuthActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks/useReduxState';

import { Constants } from '@portal/config/env';
import { translate } from '@portal/services/i18n';

import { RenderCondition } from '@portal/utils';
import { getJourneyUrlByProfileType } from '@portal/utils/goToJourney';

interface IProps {
  isExpansive?: boolean;
}

const PopoverHamburguer: React.FC<IProps> = () => {
  const {
    auth: { me },
    user: { products },
    journey,
  } = useReduxState();

  return (
    <div className="m-4">
      <RenderCondition
        condition={
          !!products?.brandProducts?.length ||
          !!products?.pijs?.length ||
          !!products?.contentLibraries?.length ||
          !!journey?.list
        }
      >
        <div className="mt-4">
          <span style={{ color: 'white', fontWeight: 700, fontSize: '16px' }}>Meus produtos:</span>
        </div>
      </RenderCondition>
      {products?.brandProducts?.map((product) => (
        <div className="product-list-wrapper">
          <a href={`${Constants.lmsUrl}/home/${product?.id}`}>
            <span className="product-list-wrapper__product-name">{product?.name}</span>
          </a>
        </div>
      ))}

      {products?.pijs?.map((product) => (
        <div className="product-list-wrapper">
          <a href={`${Constants.pijUrl}/home/${product?.id}`}>
            <span className="product-list-wrapper__product-name">{product?.name}</span>
          </a>
        </div>
      ))}

      {products?.contentLibraries?.map((product) => (
        <div className="product-list-wrapper">
          <a href={`${Constants.libUrl}/home/${product?.id}`}>
            <span className="product-list-wrapper__product-name">{product?.name}</span>
          </a>
        </div>
      ))}

      {journey?.list?.map((product) => (
        <div className="product-list-wrapper">
          <a href={getJourneyUrlByProfileType(me?.profileType ?? 0, product?.minorFolderId ?? '')}>
            <span className="product-list-wrapper__product-name">{product?.name}</span>
          </a>
        </div>
      ))}

      <div className="mt-5">
        <button
          className="product-list-wrapper__product-name"
          onClick={() => {
            AuthActions.logout();
          }}
        >
          <strong>{translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.LOGOUT')}</strong>
        </button>
      </div>
    </div>
  );
};

export default PopoverHamburguer;
