import React, { useEffect, useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { translate } from '@portal/services/i18n';
import { REVIEW_STATUS_TYPE } from '@portal/enum/reviewStatusType';
import { useReduxState } from '@portal/hooks/useReduxState';
import { isDashTutor } from '@portal/utils/isDashTutor';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { RenderCondition } from '@portal/utils';

interface IProps {
  onChangeStatus: (value: number) => void;
  statusValue?: number;
  commentValue: string;
  onChangeComment: (value: string) => void;
  disabled?: boolean;
  isUserCollapse?: boolean;
  isCourseTest?: boolean;
  isHistoric?: boolean;
  isObjective?: boolean;
  idSubItem?: string;
  isClosureTask?: boolean;
  setComment?: (value: string, valueStatusQuestion: number, referralId: string) => void;
}

const ReviewTutor: React.FC<IProps> = ({
  onChangeStatus,
  statusValue,
  commentValue,
  onChangeComment,
  disabled,
  isUserCollapse,
  isHistoric,
  idSubItem,
  isObjective,
  isClosureTask,
  setComment,
  isCourseTest,
}) => {
  const { me } = useReduxState().auth;

  const [valuesStatusQuestionSubItem, setValuesStatusQuestionSubItem] = useState<{ id: string; status: number }[]>([]);

  const [commentValueSubItem, setCommentValueSubItem] = useState<{ id: string; comment: string }[]>([]);

  const commentValueFilter = commentValueSubItem?.filter((item) => item?.id === idSubItem)?.[0]?.comment;

  const statusValueFilter = valuesStatusQuestionSubItem?.filter((item) => item?.id === idSubItem)?.[0]?.status;

  useEffect(() => {
    if (idSubItem && setComment) {
      setComment(commentValueFilter, statusValueFilter, idSubItem);
    }
  }, [commentValueSubItem, valuesStatusQuestionSubItem, idSubItem]);

  const setValueSatusSubItem = (value: number) => {
    if (!valuesStatusQuestionSubItem?.some((item) => item.id === idSubItem)) {
      setValuesStatusQuestionSubItem([
        ...(valuesStatusQuestionSubItem as []),
        { id: idSubItem as string, status: value },
      ]);
    } else {
      const newArrayStatus = valuesStatusQuestionSubItem.filter((item) => item?.id !== idSubItem);

      setValuesStatusQuestionSubItem([...newArrayStatus, { id: idSubItem as string, status: value }]);
    }
  };

  const setValueCommentSubItem = (value: string) => {
    if (!commentValueSubItem?.some((item) => item.id === idSubItem)) {
      setCommentValueSubItem([...(commentValueSubItem as []), { id: idSubItem as string, comment: value }]);
    } else {
      const newArrayStatus = commentValueSubItem.filter((item) => item?.id !== idSubItem);

      setCommentValueSubItem([...newArrayStatus, { id: idSubItem as string, comment: value }]);
    }
  };

  return (
    <div className="review-tutor">
      <RenderCondition condition={!(isObjective || (isHistoric && idSubItem) || !isDashTutor(me?.profileType))}>
        <Radio.Group
          onChange={(e: RadioChangeEvent) =>
            idSubItem ? setValueSatusSubItem(e.target.value) : onChangeStatus(e.target.value)
          }
          value={idSubItem ? statusValueFilter : statusValue}
          disabled={disabled}
        >
          <Radio className="review-tutor__radio-group__radio" value={REVIEW_STATUS_TYPE.YES}>
            {translate('PAGES.CONTENT.ACTIVITY_SECTION.RADIO_BUTTON.YES')}
          </Radio>
          {!isCourseTest && (
            <Radio className="review-tutor__radio-group__radio" value={REVIEW_STATUS_TYPE.NO}>
              {translate('PAGES.CONTENT.ACTIVITY_SECTION.RADIO_BUTTON.NO')}
            </Radio>
          )}
          {!isClosureTask && (
            <Radio className="review-tutor__radio-group__radio" value={REVIEW_STATUS_TYPE.ANOTHER_ANSWER}>
              {translate('PAGES.CONTENT.ACTIVITY_SECTION.RADIO_BUTTON.ANOTHER_ANSWER')}
            </Radio>
          )}
        </Radio.Group>
      </RenderCondition>

      <RenderCondition condition={!isHistoric && !isObjective}>
        <div className="input-textarea-container">
          <RenderCondition condition={isUserCollapse}>
            <span>{isUserCollapse && translate('PAGES.CONTENT.ACTIVITY_SECTION.ANSWER_SECTION.TITLE_TEXT_AREA')}</span>
            <span>
              <strong>{' (opcional):'}</strong>
            </span>
          </RenderCondition>
          <TextArea
            value={idSubItem ? commentValueFilter : commentValue}
            name="answer textarea"
            id="answeer textareaTutor"
            rows={5}
            onChange={(e) => (idSubItem ? setValueCommentSubItem(e.target.value) : onChangeComment(e.target.value))}
            disabled={disabled}
          />
        </div>
      </RenderCondition>
    </div>
  );
};

export default ReviewTutor;
