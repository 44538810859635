import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Input, Modal } from 'antd';
import { Row } from 'react-bootstrap';

import { sendReportVirtualGroupStudents } from '@portal/actions/virtualGroup';

import { Btn } from '@portal/components';

import { useReduxState } from '@portal/hooks/useReduxState';
import { RenderCondition } from '@portal/utils';
import { translate } from '@portal/services/i18n';

interface IModalSendReportStudents {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const ModalSendReportStudents: FC<IModalSendReportStudents> = ({ showModal, setShowModal }) => {
  const {
    group: { details },
  } = useReduxState();
  const dispatch = useDispatch();

  const [addressee, setAddressee] = useState<string>('');
  const [showFeedbackSuccess, setShowFeedbackSuccess] = useState<boolean>(false);

  const classNameRoot = 'modal-send-report-students';
  const i18nRoot = 'PAGES.GROUPS.GROUPS_PAGE.MODAL_SEND_REPORT';

  const addI18n = (route: string) => {
    return translate(`${i18nRoot}.${route}`);
  };

  const addClassName = (className: string) => {
    return `${classNameRoot}__${className}`;
  };

  const handleSubmit = () => {
    dispatch(
      sendReportVirtualGroupStudents(details?.id as string, addressee, {
        onSuccess: () => setShowFeedbackSuccess(true),
      })
    );
  };

  const onClose = () => {
    setShowModal(false);
    setShowFeedbackSuccess(false);
    setAddressee('');
  };

  return (
    <Modal visible={showModal} onCancel={onClose} bodyStyle={{ padding: '5rem' }} footer={null} width={700}>
      <div className={addClassName('root')}>
        <h1 className={addClassName('title')}>{addI18n('TITLE')}</h1>

        <h2 className={addClassName('subtitle')}>
          <strong>{addI18n('SUBTITLE')}</strong> {details?.name}
        </h2>

        <RenderCondition condition={!showFeedbackSuccess}>
          <Form onFinish={handleSubmit}>
            <label className={addClassName('label')}>{addI18n('LABEL')}</label>
            <Input
              className={addClassName('input')}
              onChange={(e) => setAddressee(e.target.value)}
              value={addressee}
              placeholder={addI18n('INPUT_PLACEHOLDER')}
              type="email"
              size="large"
              required
            />

            <Row className={addClassName('footer')}>
              <Btn variant="secondary" onClick={onClose} type="button">
                {addI18n('FOOTER.CANCEL')}
              </Btn>
              <Btn type="submit">{addI18n('FOOTER.SEND')}</Btn>
            </Row>
          </Form>
        </RenderCondition>

        <RenderCondition condition={showFeedbackSuccess}>
          <div className={addClassName('feedback-success')}>
            <p className={addClassName('feedback-success__description')}>
              {addI18n('FEEDBACK_SUCCESS.FEEDBACK')}
              <br />
              {addI18n('FEEDBACK_SUCCESS.INFO')}
            </p>
            <Row className={addClassName('footer')}>
              <Btn onClick={onClose}>{addI18n('FOOTER.CLOSE')}</Btn>
            </Row>
          </div>
        </RenderCondition>
      </div>
    </Modal>
  );
};

export default ModalSendReportStudents;
