import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Pagination } from 'antd';
import { Row } from 'react-bootstrap';

import { CourseActions } from '@portal/actions';
import { getLmsTrailsWithCourses } from '@portal/actions/lmsTrail';
import { useReduxState } from '@portal/hooks/useReduxState';

import { Card, Carousel, FilterSearch } from '~/components';
import IcUniqueArrowDown from '@portal/components/v1/Icon/IcUniqueArrowDown';
import EmptyCourse from '@portal/assets/png/components/empty_course.png';

import { translate } from '@portal/services';
import {
  COURSE_TYPE,
  ProductType,
  ProfileType,
  StepsLmsOnboardingLmsEducator,
  StepsLmsOnboardingStudent,
} from '@portal/enum';
import { isTrailProfile, RenderCondition } from '@portal/utils';
import * as StorageService from '~/services/storage';
import returnPronounWithCourseTerm from '@portal/utils/returnPronounWithCourseTerm';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const AllCourses: React.FC = () => {
  const dispatch = useDispatch();

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';
  const productId = StorageService.getItem('brandProductsConfig')?.id;
  const params: { id: string } = useParams();

  const [openTrails, setOpenTrails] = useState<string[]>([]);

  const {
    auth: { me },
    course: { mineCourses, otherCourses },
    brand: { brandProductConfig },
    lmsTrail: { listWithCourses },
    lmsOnboarding: { stepActive, showModalStepsOnboarding },
  } = useReduxState();

  const [CourseListPagination, setCourseListPagination] = useState({
    limit: 10,
    offset: 1,
    productId: params?.id ? params?.id : productId,
    schoolId: me?.idSchool,
  });

  useEffect(() => {
    if (
      me?.idSchool &&
      (productId || params.id) &&
      isTrailProfile(me?.profileType) &&
      productType === ProductType.LMS_EDUCATOR
    ) {
      dispatch(
        CourseActions.getOtherCourses({
          ...CourseListPagination,
          schoolId: me?.idSchool || '',
          type: COURSE_TYPE.TRAIL,
        })
      );
      dispatch(
        CourseActions.getMineCourses({
          ...CourseListPagination,
          schoolId: me?.idSchool || '',
          type: COURSE_TYPE.TRAIL,
        })
      );
    } else if (me?.idSchool && (productId || params.id)) {
      dispatch(
        CourseActions.getOtherCourses({
          ...CourseListPagination,
          schoolId: me?.idSchool || '',
          type: COURSE_TYPE.ELECTIVE,
        })
      );
      dispatch(
        CourseActions.getMineCourses({
          ...CourseListPagination,
          type: COURSE_TYPE.ELECTIVE,
        })
      );
    }
  }, [me, productType, CourseListPagination, me?.idSchool, productId]);

  useEffect(() => {
    if (brandProductConfig?.id && brandProductConfig?.trailList) {
      dispatch(
        getLmsTrailsWithCourses(
          { idBrandProduct: brandProductConfig?.id },
          { onSuccess: (data) => setOpenTrails(data?.map((trail) => trail?.id as string)) }
        )
      );
    }
  }, [brandProductConfig?.id, brandProductConfig?.trailList]);

  useEffect(() => {
    if (listWithCourses) {
      setOpenTrails(listWithCourses?.map((trail) => trail?.id as string));
    }
  }, [listWithCourses]);

  useEffect(() => {
    if (showModalStepsOnboarding && listWithCourses) {
      setOpenTrails(listWithCourses?.map((trail) => trail?.id as string));
    }
  }, [listWithCourses, showModalStepsOnboarding]);

  const handleOpenTrails = (idTrail: string) => {
    if (openTrails?.includes(idTrail)) {
      setOpenTrails((state) => state?.filter((trail) => trail !== idTrail));

      return;
    }

    setOpenTrails((state) => [...state, idTrail]);
  };

  const isStarted = !!mineCourses?.rows?.length;

  const hasCourses =
    !!otherCourses?.rows?.length || !!listWithCourses?.filter?.((lmsTrail) => !!lmsTrail?.trailCourses?.length)?.length;

  const isStudent = me?.profileType === ProfileType.STUDENT;

  const showOtherCourses = !!hasCourses || !isStarted;

  return (
    <div className="all-courses">
      <RenderCondition condition={isStarted}>
        <h1 className="all-courses__title">{translate('COMPONENTS.NAVIGATION_MENU.CONTINUE_STUDYING')}</h1>

        <Carousel array={mineCourses?.rows}>
          {mineCourses?.rows?.map((course) => (
            <div key={course.id} className="all-courses__course-list__carousel-item" id="mine-courses">
              <Card
                key={course.id}
                cardData={course}
                isStarted
                trail={listWithCourses?.find((trail) =>
                  trail?.trailCourses?.some((trailCourse) => trailCourse?.idCourse === course?.id)
                )}
              />
            </div>
          ))}
        </Carousel>
      </RenderCondition>

      <RenderCondition condition={hasCourses}>
        <div className="all-courses__filter">
          <FilterSearch />
        </div>
      </RenderCondition>

      <RenderCondition condition={isStudent && showOtherCourses}>
        <h1 className="all-courses__title">{returnPronounWithCourseTerm()?.other}:</h1>
      </RenderCondition>

      <div>
        <RenderCondition condition={brandProductConfig?.trailList}>
          {listWithCourses
            ?.filter?.((lmsTrail) => !!lmsTrail?.trailCourses?.length)
            ?.map((lmsTrail, key) => (
              <div
                key={lmsTrail?.id}
                {...(stepActive === StepsLmsOnboardingLmsEducator.TRAILS &&
                  key === 0 && {
                    style: { zIndex: 99999, backgroundColor: 'white', position: 'relative', borderRadius: '8px' },
                  })}
              >
                <div
                  style={{
                    marginBottom: '1.5rem',
                    marginTop: '4.125rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    color: brandProductConfig?.primaryColor,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenTrails(lmsTrail?.id as string)}
                >
                  <IcUniqueArrowDown
                    style={{
                      transition: 'all 0.4s',
                      transform: openTrails?.includes(lmsTrail?.id as string) ? 'rotate(0)' : 'rotate(-90deg)',
                    }}
                    className="all-courses__title-trail__icon"
                  />
                  <span className="all-courses__title-trail" {...(key === 0 && { id: 'lms-trail' })}>
                    {lmsTrail?.name}
                  </span>
                </div>
                <RenderCondition condition={openTrails?.includes(lmsTrail?.id as string)}>
                  <Carousel array={lmsTrail?.trailCourses}>
                    {lmsTrail?.trailCourses?.map((trailCourse) => (
                      <div key={trailCourse.id} className="all-courses__course-list__carousel-item">
                        <Card
                          cardData={trailCourse.course}
                          isDisabledSeeDetails={mineCourses?.rows?.some(
                            (course) => course?.id === trailCourse?.idCourse
                          )}
                        />
                      </div>
                    ))}
                  </Carousel>
                </RenderCondition>
              </div>
            ))}
        </RenderCondition>
        <RenderCondition condition={!brandProductConfig?.trailList && showOtherCourses}>
          <div>
            {otherCourses?.rows?.length ? (
              <div className="all-courses__course-list">
                {otherCourses.rows.map((course) => (
                  <Card
                    key={course.id}
                    cardData={course}
                    isInFocus={stepActive === StepsLmsOnboardingStudent.ELECTIVES && isStudent}
                  />
                ))}
              </div>
            ) : (
              <div className="all-courses__course-list__empty-container">
                <div>
                  <Row className="d-flex justify-content-center align-itens-center">
                    <img
                      className="all-courses__course-list__empty-container__image w-100"
                      src={EmptyCourse}
                      alt="empty course"
                    />
                  </Row>
                  <Row className="d-flex justify-content-center align-itens-center" hidden={!!mineCourses}>
                    <span className="all-courses__course-list__empty-container__title">
                      {translate('PAGES.COURSES.EMPTY_OTHER_COURSES')}
                    </span>
                  </Row>
                </div>
              </div>
            )}
          </div>

          <RenderCondition condition={hasCourses}>
            <div className="all-courses__course-list__footer">
              <Pagination
                current={CourseListPagination.offset}
                total={otherCourses.count}
                pageSize={CourseListPagination.limit}
                onChange={(page) =>
                  setCourseListPagination({
                    ...CourseListPagination,
                    offset: page,
                  })
                }
              />
            </div>
          </RenderCondition>
        </RenderCondition>
      </div>
    </div>
  );
};

export default AllCourses;
