import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { setShowTestHistory } from '@portal/actions/courseTest';

import Btn from '@portal/components/v1/Btn/Btn';

import { RenderCondition } from '@portal/utils';
import { useReduxState } from '@portal/hooks/useReduxState';
import { TasksStatus } from '@portal/enum/taskStatus';
import { translate } from '@portal/services/i18n';
import { BUTTON_SIZE } from '@portal/enum/buttonSize';
import { getStackPath } from '@portal/config/routes';

export const CourseTestFeedback: React.FC = () => {
  const history = useHistory();
  const {
    courseTest: { courseTest, courseTestDone },
    course: { courseProgress },
  } = useReduxState();

  const dispatch = useDispatch();

  return (
    <div className="final-evaluation-section__test-grade">
      <div className="final-evaluation-section__test-grade__container">
        <RenderCondition condition={courseProgress?.test?.testStatus === TasksStatus.DONE}>
          <span className="final-evaluation-section__test-grade__title">
            {translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.TITLE_POSITIVE')}
          </span>

          <div className="final-evaluation-section__test-grade__content">
            <span className="final-evaluation-section__test-grade__description">
              {translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.DESCRIPTION_GRADE')}
            </span>
            <span className="final-evaluation-section__test-grade__grade-positive">
              {Number(courseTestDone?.course?.courseUser[0]?.testGrade || 0)?.toFixed(0)}%
            </span>
          </div>

          <Btn
            title={translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.HEADER.CONTINUE')}
            size={BUTTON_SIZE.MEDIUM}
            onClick={() => history.push(`${getStackPath('CONTENT')}/${courseTestDone?.course?.id}/satisfaction-survey`)}
          />

          <div
            className="final-evaluation-section__test-grade__link"
            onClick={() => dispatch(setShowTestHistory(true))}
          >
            {translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.MY_EVALUATION')}
          </div>
        </RenderCondition>
        <RenderCondition condition={courseProgress?.test?.testStatus === TasksStatus.REDO}>
          <span className="final-evaluation-section__test-grade__title">
            {translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.TITLE_NEGATIVE')}
          </span>

          <div className="final-evaluation-section__test-grade__content">
            <span className="final-evaluation-section__test-grade__description">
              {translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.DESCRIPTION_GRADE')}
            </span>
            <span className="final-evaluation-section__test-grade__grade-negative">
              {Number(courseTestDone?.course?.courseUser[0]?.testGrade)?.toFixed(0)}%
            </span>
            <div className="final-evaluation-section__test-grade__label">
              {translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.LABEL', {
                grade: Number(courseTest?.percForApproval)?.toFixed(0),
              })}
            </div>
          </div>

          <Btn
            title={translate('PAGES.CONTENT.FINAL_EVALUATION.FEEDBACK.TRY_AGAIN')}
            size={BUTTON_SIZE.MEDIUM}
            onClick={() => dispatch(setShowTestHistory(true))}
          />
        </RenderCondition>
      </div>
    </div>
  );
};

export default CourseTestFeedback;
