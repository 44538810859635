import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';

import CertificatePage from './CertificatePage/CertificatePage';

import { CertificatePageTitle } from './CertificatePage/components/CertificatePageTitle/CertificatePageTitle';

const PublicNavigationStack: React.FC = () => {
  return (
    <Switch>
      <Route path={`${getStackPath('CERTIFICATE')}/:id?`}>
        <CertificatePageTitle>
          <CertificatePage />
        </CertificatePageTitle>
      </Route>
    </Switch>
  );
};

export default PublicNavigationStack;
