import React, { useEffect, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';

import DragAndDrop from '@portal/components/v1/UI/Molecules/DragAndDrop/DragAndDrop';
import Feedback from '@portal/components/v1/Question/Feedback/Feedback';
import StatusLabel from '@portal/components/v1/ContentComponents/StatusLabel/StatusLabel';

import { ProductType } from '@portal/enum/productType';
import { useReduxState } from '@portal/hooks/useReduxState';
import { translate } from '@portal/services/i18n';
import { isDashTutor } from '@portal/utils/isDashTutor';
import * as StorageService from '~/services/storage';
import { RenderCondition } from '@portal/utils';
import { TasksStatus } from '@portal/enum/taskStatus';
import RenderHTML from '@portal/components/v1/RenderHTML/RenderHTML';

interface IProps {
  isDisabled?: boolean;
  setFileAnswer?: (value: string) => void;
  question?: any;
  isSolution?: boolean;
}

const CourseTestUploadQuestion: React.FC<IProps> = ({ isDisabled, setFileAnswer, question, isSolution }) => {
  const [fileUrl, setFileUrl] = useState<string>('');

  const {
    auth: { me },
    course: { courseProgress },
    courseTest: { courseTestDone },
  } = useReduxState();

  const currentAnswer = () => {
    const questionSelected = courseTestDone?.questions?.find((item) => item.id === question?.id);

    if (question?.answers?.length > 0) {
      return question?.answers?.[question?.answers?.length - 1];
    }

    return questionSelected?.answers?.[questionSelected?.answers?.length - 1];
  };

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const isTutor = isDashTutor(me?.profileType);

  const setAnswer = (file: string) => {
    if (setFileAnswer) {
      setFileAnswer(file);
    }
  };

  useEffect(() => {
    if (fileUrl) {
      setAnswer(fileUrl);
    }
  }, [fileUrl]);

  useEffect(() => {
    if (currentAnswer()?.answer) {
      setFileUrl(currentAnswer()?.answer || '');
    }
  }, [question, courseTestDone]);

  const disabledUpload =
    (currentAnswer()?.answer?.length as number) > 0 && courseProgress?.test?.testStatus !== TasksStatus.REDO;

  return (
    <div className="question-upload-test-container">
      <StatusLabel
        currentStatus={courseProgress?.test?.testStatus || -1}
        doneStatus={TasksStatus.DONE}
        waitingReviewStatus={TasksStatus.WAITING_REVIEW}
        redoStatus={TasksStatus.REDO}
        doneLabel={translate('PAGES.CONTENT.FINAL_EVALUATION.EVALUATION_STATUS.DONE')}
        waitingReviewLabel={translate('PAGES.CONTENT.FINAL_EVALUATION.EVALUATION_STATUS.WAITING')}
        redoLabel={translate('PAGES.CONTENT.FINAL_EVALUATION.EVALUATION_STATUS.REDO')}
      />
      <div className="question-upload-test-container__title">
        <RenderCondition condition={question?.description}>
          <div className="question-upload-test-container__title__label">
            <RenderHTML htmlText={question?.description as string} />
          </div>
        </RenderCondition>
      </div>

      <RenderCondition condition={!isSolution}>
        <div className="question-upload-test-container__answer">
          {(isTutor && productType === ProductType.LMS_STUDENT) || disabledUpload ? (
            <div className="download-pdf">
              <a
                href={fileUrl || ''}
                target="_blank"
                download={fileUrl || ''}
                style={{ fontSize: '13px', fontWeight: 'normal' }}
              >
                <u>{translate('COMPONENTS.RENDER_CONTENT.DOWNLOAD_ANWSER')}</u>
              </a>
              <DownloadOutlined className="icon" />
            </div>
          ) : (
            <div className="question-upload-test-container__answer__dragAndDrop">
              <DragAndDrop
                onChange={(value: string) => setFileUrl(value)}
                disabled={isDisabled || disabledUpload}
                value={fileUrl}
              />
            </div>
          )}
        </div>
      </RenderCondition>

      {currentAnswer()?.comments?.map((comment: models.QuestionComment) => (
        <div key={comment.id} className="final-question-container__feedback">
          <RenderCondition condition={!!comment.comment}>
            <Feedback
              description={comment?.comment}
              name={comment?.user?.name}
              creatorId={comment?.user?.id}
              thumbSrc={comment?.user?.profilePictureUrl}
            />
          </RenderCondition>
        </div>
      ))}
    </div>
  );
};

export default CourseTestUploadQuestion;
