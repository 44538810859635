/* eslint-disable max-len */
import React, { ComponentProps } from 'react';

export const IcCertificateGlobalSignature = (props: ComponentProps<'svg'>) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="258.000000pt"
    height="86.000000pt"
    viewBox="0 0 258.000000 86.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g transform="translate(0.000000,86.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M390 575 c-143 -74 -188 -126 -164 -189 11 -29 27 -17 20 14 -5 25 0
  37 27 65 31 33 173 117 219 130 27 7 -6 -25 -155 -153 -53 -46 -97 -87 -97
  -92 0 -26 26 -11 121 70 57 49 107 90 111 90 4 0 0 -16 -9 -35 -24 -50 -8 -64
  50 -46 92 27 90 27 71 -6 -12 -20 -15 -36 -9 -49 5 -10 9 -23 9 -29 2 -22 33
  -14 44 10 14 29 9 45 -14 45 -11 0 -9 9 11 37 36 54 20 63 -65 34 -39 -13 -73
  -22 -76 -18 -3 3 1 16 10 29 9 12 16 37 16 55 0 18 4 33 9 33 13 0 21 27 15
  45 -10 24 -33 18 -144 -40z"
      />
      <path
        d="M848 551 c-36 -39 -79 -99 -97 -135 -22 -44 -38 -66 -50 -66 -23 0
  -31 -9 -24 -25 3 -9 11 -11 26 -6 11 4 33 3 49 -3 23 -8 32 -7 57 12 34 25 48
  27 53 10 6 -16 61 -12 98 7 17 9 48 15 68 14 75 -5 91 0 107 31 8 16 15 31 15
  35 0 12 -24 3 -52 -20 -16 -14 -38 -25 -49 -25 -20 0 -26 15 -9 25 15 9 12 35
  -4 35 -7 0 -21 -11 -30 -25 -21 -33 -102 -73 -116 -59 -7 7 0 24 22 54 18 24
  37 49 43 56 5 7 6 16 2 21 -4 4 -37 -22 -72 -58 -36 -36 -76 -72 -90 -79 -27
  -15 -45 -6 -30 14 6 7 47 62 92 122 45 60 82 115 83 122 0 28 -32 8 -92 -57z"
      />
      <path
        d="M1486 599 c-60 -32 -123 -102 -219 -245 -89 -132 -111 -182 -96 -223
  12 -31 30 -17 23 19 -10 45 81 190 119 190 7 0 37 18 67 40 29 22 55 38 57 36
  2 -2 -6 -20 -17 -39 -35 -58 -10 -73 60 -37 l40 20 0 -24 c0 -42 34 -39 91 7
  28 23 62 48 75 55 13 7 31 26 41 42 9 17 22 30 28 30 6 0 20 -19 32 -43 41
  -85 130 -87 109 -2 -11 44 -43 68 -82 60 -47 -8 -54 15 -20 59 14 19 26 39 26
  45 0 23 -21 9 -52 -34 -18 -24 -50 -52 -70 -62 -30 -13 -38 -23 -38 -43 0 -17
  -14 -40 -43 -68 -45 -44 -75 -56 -62 -24 26 62 15 66 -57 22 -26 -16 -50 -30
  -53 -30 -13 0 -2 29 18 48 13 13 45 54 71 92 34 50 47 77 44 97 -4 36 -38 41
  -92 12z m64 -28 c0 -28 -110 -122 -218 -185 -48 -29 89 130 152 176 45 33 66
  36 66 9z m304 -127 c18 -17 22 -54 6 -54 -15 0 -50 38 -50 55 0 20 24 19 44
  -1z"
      />
      <path
        d="M2012 520 c-50 -23 -63 -47 -36 -67 16 -12 47 -14 177 -8 86 3 162 3
  167 0 11 -7 -11 -57 -38 -88 -8 -9 -12 -20 -8 -24 13 -13 41 13 65 62 l25 48
  -24 19 c-21 17 -35 18 -181 12 -109 -5 -159 -4 -159 3 0 30 123 44 134 16 5
  -13 9 -14 19 -4 9 10 7 17 -12 32 -29 24 -74 24 -129 -1z"
      />
    </g>
  </svg>
);
