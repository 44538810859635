import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import LmsNavigationStack from '@portal/pages/LMS/LMSNavigationStack';
import PublicNavigationStack from '@portal/pages/Public/Certificate/PublicNavigationStack';

import { RenderCondition } from './utils';

import { isPublicRoute } from './config/routes';

const Router = () => {
  return (
    <BrowserRouter>
      <RenderCondition condition={!isPublicRoute}>
        <LmsNavigationStack />
      </RenderCondition>

      <RenderCondition condition={isPublicRoute}>
        <PublicNavigationStack />
      </RenderCondition>
    </BrowserRouter>
  );
};

export default Router;
