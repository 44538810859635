import { Dispatch } from 'react';

import { decreaseLoading, increaseLoading } from '../loading';

import brandAPI from '@portal/repositories/brand';

import { IBrand, ICallbackOptions } from '@portal/types';

export const brandActions = {
  getBrandConfig: (options?: ICallbackOptions<IBrand>) => async (dispatch: Dispatch<any>) => {
    dispatch(increaseLoading());

    try {
      const response = (await brandAPI.getBrandConfig()) as IBrand;

      options?.onSuccess?.(response);
    } catch {
      options?.onError?.();
    } finally {
      dispatch(decreaseLoading());
    }
  },
};
