import { getInstance } from './instance';
const BASE_URL = '/certificate';

const certificateAPI = {
  getCertificateDownloadUrl: async (params: {
    userId?: string;
    courseId: string;
    brandProductId: string;
  }): Promise<string> => {
    const instance = getInstance();
    const { data } = await instance.post(`${BASE_URL}/manually`, params);

    return data;
  },

  getTotalHoursCertificateDownloadUrl: async (params: { brandProductId: string }): Promise<string> => {
    const instance = getInstance();
    const { data } = await instance.post(`${BASE_URL}/total-hours`, params);

    return data;
  },

  getUserCertificateInfos: async (courseUserId: string, idSchool: string) => {
    const instance = getInstance({ api: 'new', version: 'v2' });

    const { data } = await instance.get(`/course-user/${courseUserId}?idSchool=${idSchool}`);

    return data;
  },
};

export default certificateAPI;
