/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { AsyncPaginate } from 'react-select-async-paginate';

import { RenderCondition } from '~/utils';

interface SelectAsyncPaginateProps {
  onChange: any;
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  valueFilter?: any;
  valueDefault?: any;
  title?: string;
  asyncFunction: (props: any) => Promise<any>;
  filters?: any;
}

const SelectAsyncPaginate: React.FC<SelectAsyncPaginateProps> = ({
  onChange,
  asyncFunction,
  isDisabled,
  valueFilter,
  isMulti,
  placeholder,
  valueDefault = null,
  title,
  filters,
}) => {
  const [value, setValue] = useState<any>(null);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '2.7rem',
      borderColor: '#d9d9d9',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#7F8182',
      fontSize: '16px',
    }),
  };

  useEffect(() => {
    if (!valueFilter) {
      setValue(null);
    }
  }, [valueFilter]);

  async function loadOptions(search: any, _loadedOptions: any, { page }: any) {
    const { rows, count } = await asyncFunction({
      offset: page,
      page: page,
      limit: 10,
      name: search,
      ...filters,
    });

    return {
      options: rows?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      hasMore: count >= page,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <>
      <RenderCondition condition={!!title}>
        <div className={isDisabled ? 'select-complete__title__disabled' : 'select-complete__title'}>{title}</div>
      </RenderCondition>
      <AsyncPaginate
        styles={customStyles}
        placeholder={placeholder}
        debounceTimeout={2000}
        value={value || valueDefault}
        loadOptions={loadOptions}
        onChange={(e: any) => {
          onChange(e), setValue(e);
        }}
        additional={{
          page: 0,
        }}
        isMulti={isMulti}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default SelectAsyncPaginate;
