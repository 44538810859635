import { LoadingOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import { useReduxState } from '@portal/hooks/useReduxState';
import { translate } from '@portal/services/i18n';
import { RenderCondition } from '@portal/utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  url: string;
}

const RenderPDF: React.FC<IProps> = ({ url }) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const divPDFRef = useRef<HTMLDivElement>(document.createElement('div'));

  const { isHide } = useReduxState().sidebarContent;

  const handlePDFWidth = useCallback(() => {
    if (timer !== null) {
      window.clearTimeout(timer);
      setTimer(null);
    } else {
      setTimer(setTimeout(() => setPdfWidth(divPDFRef.current?.offsetWidth), 500));
    }
  }, [timer]);

  const LoadingPDFComponent = (
    <div className="loading-pdf">
      <LoadingOutlined />
      <span>{translate('COMPONENTS.RENDER_CONTENT.PDF_LOADING_LABEL')}</span>
    </div>
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPdfWidth(divPDFRef.current?.offsetWidth);
  };

  const dataPDF = () => {
    const data = [];
    for (let i = 0; i < numPages; i += 1) {
      data.push(i);
    }
    return data;
  };

  useEffect(() => {
    window.addEventListener('resize', handlePDFWidth);
    return () => {
      window.removeEventListener('resize', handlePDFWidth);
    };
  }, [handlePDFWidth, isHide]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  return (
    <div className="render-pdf" ref={divPDFRef}>
      <RenderCondition condition={isLoading}>
        <div className="render-pdf__loading">{LoadingPDFComponent}</div>
      </RenderCondition>
      <RenderCondition condition={!isLoading}>
        <Document file={url} loading={() => LoadingPDFComponent} onLoadSuccess={onDocumentLoadSuccess}>
          {dataPDF().map((page) => (
            <div className="render-content__render-pdf" key={page}>
              <Page pageIndex={page} width={pdfWidth} renderMode="svg" />
            </div>
          ))}
        </Document>
      </RenderCondition>
    </div>
  );
};

export default RenderPDF;
