import React, { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import { RenderCondition } from '@portal/utils';
import { brandActions } from '@portal/actions/v2/brand';
import { certificateActions } from '@portal/actions/v2/certificate';
import { IBrand, ICallbackOptions, IUserCertificateInfos } from '@portal/types';

import { CustomLoading } from '@portal/components/v1/Loading';

import {
  IcLogo,
  IcCertificateError,
  IcCertificateMedallion,
  IcCertificateGlobalSignature,
} from '@portal/components/v2/atoms/Icons';

import './CertificatePage.less';

import { useDispatch } from 'react-redux';

const CertificatePage: React.FC = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { id } = useParams<{ id: string }>();

  const idSchool = new URLSearchParams(useLocation().search).get('idSchool') as string;

  const [brandConfig, setBrandConfig] = useState<IBrand>({} as IBrand);
  const [userCertificateInfos, setUserCertificateInfos] = useState<IUserCertificateInfos>({} as IUserCertificateInfos);

  const getUserCertificateInfos = (options?: ICallbackOptions<undefined>) => {
    dispatch(
      certificateActions.getUserCertificateInfos(id, idSchool, {
        onSuccess: (data) => {
          if (data) {
            setUserCertificateInfos(data);
          }

          options?.onSuccess?.();
        },
        onError: () => {
          setHasError(true);

          options?.onError?.();
        },
      })
    );
  };

  const getBrandConfig = (options?: ICallbackOptions<IBrand>) => {
    dispatch(
      brandActions.getBrandConfig({
        onSuccess: (data?: IBrand | null) => {
          if (data) {
            setBrandConfig(data);
          }

          setLoading(false);

          options?.onSuccess?.();
        },
        onError: () => {
          setLoading(false);

          options?.onError?.();
        },
      })
    );
  };

  useEffect(() => {
    Promise.all([getBrandConfig(), getUserCertificateInfos()]);
  }, []);

  const { primaryColor } = brandConfig;
  const { userName, schoolName, courseName, brandName, brandLogoIcon, hoursDuration } = userCertificateInfos;

  const hasBrandConfig = Object.keys(brandConfig).length > 0;
  const hasUserCertificateInfos = Object.keys(userCertificateInfos).length > 0;
  const showCertificate = !loading && !hasError && hasBrandConfig && hasUserCertificateInfos;

  const signatureName = 'Milene Bento Alves';
  const signatureRole = 'DIRETOR GERAL ARCOPLUS';

  return (
    <>
      {<CustomLoading brandPrimaryColor={primaryColor} loading={loading} />}

      <RenderCondition condition={showCertificate}>
        <div className="certificate-page">
          <div className="certificate-container">
            <img
              src={brandLogoIcon}
              width={100}
              height={50}
              alt="Logotipo Marca"
              style={{
                marginBottom: '-1rem',
              }}
            />

            <div className="certificate-content">
              <h1>CERTIFICADO DE CONCLUSÃO</h1>

              <div className="certificate-content-info">
                <p className="certificate-content-info-title">NOME</p>
                <p className="certificate-content-info-description">{userName}</p>
              </div>

              <div className="certificate-content-info">
                <p className="certificate-content-info-title">CURSO</p>
                <p className="certificate-content-info-description">{courseName}</p>
              </div>

              <div className="certificate-content-info">
                <p className="certificate-content-info-title">CARGA HORÁRIA</p>
                <p className="certificate-content-info-description">{hoursDuration} horas</p>
              </div>

              <div className="certificate-content-info">
                <p className="certificate-content-info-title">INSTITUIÇÃO DE ENSINO</p>
                <p className="certificate-content-info-description">{schoolName}</p>
              </div>

              <div className="certificate-content-info">
                <p className="certificate-content-info-title">PROGRAMA EDUCACIONAL</p>
                <p className="certificate-content-info-description">{brandName}</p>
              </div>
            </div>

            <div className="certificate-footer">
              <div className="certificate-footer-icon">
                <div className="certificate-footer-icon-content">
                  <IcCertificateMedallion width={175} height={75} className="certificate-medallion-icon" />

                  <IcCertificateGlobalSignature width={175} height={75} />

                  <hr
                    style={{
                      maxWidth: '200px',
                      marginTop: '-2.5rem',
                    }}
                  />
                </div>

                <div className="certificate-footer-signature">
                  <h3>{signatureName}</h3>
                  <p>{signatureRole}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderCondition>

      <RenderCondition condition={hasError}>
        <div className="certificate-error-page">
          <div className="certificate-error-container">
            <IcLogo />

            <div className="certificate-error-content">
              <IcCertificateError />

              <div className="certificate-error-content-info">
                <h1>CERTIFICADO NÃO ENCONTRADO</h1>
                <p>
                  Não foi possível exibir o certificado. Aponte a câmera do celular para o QR Code e tente novamente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </RenderCondition>
    </>
  );
};

export default CertificatePage;
