import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Popover } from 'antd';

import { AuthActions, HomeNotificationActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks/useReduxState';

import { Btn, PopoverNotification } from '@portal/components';

import { Constants } from '@portal/config/env';
import { translate } from '@portal/services/i18n';
import { isDashProfile } from '@portal/utils/isDashProfile';

import { IcBell, IcWindow, IcProfile, IcHouse } from '@portal/assets/Icon';
import { QuestionCircleFilled } from '@ant-design/icons';
import { getCookie } from '@portal/services/cookie';
import * as CookieService from '~/services/cookie';
import { RenderCondition } from '@portal/utils';
import PopoverHamburguer from '../PopoverHamburguer';

interface IProps {
  isExpansive?: boolean;
}

const ContentTopBar: React.FC<IProps> = ({ isExpansive }) => {
  const {
    auth: { me },
    brand: { brandConfig },
    config: { configPortal },
  } = useReduxState();

  const dispatch = useDispatch();

  const isSaePartnership = me?.isSaePartnership || getCookie('user-integration');

  return (
    <div
      className={
        isExpansive
          ? 'hamburguer-menu'
          : `panel-content-top-bar__navbar-container${isSaePartnership ? '--hidden-content' : ''}`
      }
    >
      <div
        className={
          isExpansive
            ? 'm-4'
            : `mr-2 panel-content-top-bar__navbar-container${isSaePartnership ? '--hidden-content' : ''}`
        }
      >
        <RenderCondition condition={!isSaePartnership}>
          <div>
            <button>
              <span
                className="panel-content-top-bar__navbar-container__buttons"
                onClick={() => {
                  isDashProfile(me?.profileType)
                    ? (window.location.href = `${Constants.dashboardUrl}/home`)
                    : (window.location.href = `${Constants.portalUrl}/home`);
                }}
              >
                <IcHouse />
                {translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.START')}
              </span>
            </button>
          </div>
        </RenderCondition>

        <div>
          <a href={configPortal.faq} target="blank">
            <span className="panel-content-top-bar__navbar-container__buttons">
              <QuestionCircleFilled style={{ fontSize: '.875rem' }} />
              {translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.FAQ')}
            </span>
          </a>
        </div>

        <RenderCondition condition={!isSaePartnership}>
          <RenderCondition condition={!isExpansive}>
            <div>
              <Popover trigger="click" content={<PopoverNotification />}>
                <button
                  className="panel-content-top-bar__navbar-container__buttons"
                  onClick={() => dispatch(HomeNotificationActions.readNotifications())}
                >
                  <IcBell />
                  {translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.NOTIFICATIONS')}
                </button>
              </Popover>
            </div>
            <div>
              <Popover
                className={
                  isExpansive
                    ? 'panel-content-top-bar__navbar-container__buttons'
                    : 'hidden panel-content-top-bar__navbar-container__buttons'
                }
                trigger="click"
                overlayInnerStyle={{ backgroundColor: '#e5e5e5ff' }}
              >
                <button className="panel-content-top-bar__navbar-container__buttons ">
                  <IcWindow />
                  {translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.PRODUCTS')}
                </button>
              </Popover>
            </div>
          </RenderCondition>
          <div>
            <button onClick={() => (window.location.href = `${Constants.portalUrl}/perfil/${me?.id}`)}>
              <span
                className={
                  isExpansive
                    ? 'panel-content-top-bar__navbar-container__buttons'
                    : 'hidden panel-content-top-bar__navbar-container__buttons'
                }
              >
                <IcProfile />
                {translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.PROFILE')}
              </span>
            </button>
          </div>
        </RenderCondition>
      </div>
      <div className={`${isExpansive ? 'hiddenAvatar' : ''}`}>
        <Popover
          trigger="click"
          content={
            isSaePartnership ? (
              <a href="https://ava.sae.digital/_n/ava/electives">
                <Btn onClick={() => CookieService.deleteCookie('user-integration', '/', Constants.brandCookieDomain)}>
                  <span className="font-weight-bold">{translate('COMPONENTS.NAVIGATION_MENU.BACK_TO_AVA')}</span>
                </Btn>
              </a>
            ) : (
              <Btn
                onClick={() => {
                  AuthActions.logout();
                }}
              >
                <span className="font-weight-bold">{translate('COMPONENTS.PANEL_CONTENT_TOP_BAR.NAVBAR.LOGOUT')}</span>
              </Btn>
            )
          }
        >
          <Avatar
            className="panel-content-top-bar__navbar-container__avatar"
            size={49}
            src={brandConfig?.logoIconUrl ? brandConfig?.logoIconUrl : ''}
          />
        </Popover>
      </div>

      <RenderCondition condition={isExpansive && !isSaePartnership}>
        <PopoverHamburguer />
      </RenderCondition>
    </div>
  );
};

export default ContentTopBar;
