import { CheckOutlined, FormOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { SatisfactionSurveyStatus } from '@portal/enum/satisfactionSurvey';
import { TasksStatus } from '@portal/enum/taskStatus';
import { RenderCondition } from '@portal/utils';
import { isDashTutor } from '@portal/utils/isDashTutor';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as StorageService from '~/services/storage';
import { ProductType } from '@portal/enum/productType';

interface IProps {
  status?: number;
  selected?: number;
  isSatisfactionSurvey?: boolean;
  count?: number;
}

const SidebarDropdownTaskStatus: React.FC<IProps> = ({ status, selected, isSatisfactionSurvey, count }) => {
  const {
    auth: { me },
  } = useReduxState();

  const productType = StorageService.getItem('brandProductsConfig')?.product || '';

  const isTutor = isDashTutor(me?.profileType) && productType === ProductType.LMS_STUDENT;

  const returnStatusCompoent = useCallback(
    (statusProp?: number) => {
      const done = (
        <div className="sidebar-dropdown-task-status__container sidebar-dropdown-task-status__container--done">
          <CheckOutlined style={{ color: `${selected ? '#fff' : '#000'}` }} />
        </div>
      );

      const waiting = (
        <div className="sidebar-dropdown-task-status__container sidebar-dropdown-task-status__container--waiting">
          <FormOutlined style={{ color: `${selected ? '#fff' : '#000'}` }} />
        </div>
      );

      const redo = (
        <div className="sidebar-dropdown-task-status__container sidebar-dropdown-task-status__container--redo">
          <InfoCircleOutlined style={{ color: `${selected ? '#fff' : '#000'}` }} />
        </div>
      );
      if (isSatisfactionSurvey) {
        switch (statusProp) {
          case SatisfactionSurveyStatus.DONE:
            return done;
        }
      } else {
        switch (statusProp) {
          case TasksStatus.DONE:
            return done;
          case TasksStatus.WAITING_REVIEW:
            return waiting;
          case TasksStatus.REDO:
            return redo;
        }
      }
    },
    [selected]
  );

  return (
    <div
      className={
        isTutor && count
          ? 'sidebar-dropdown-task-status-tutor'
          : `sidebar-dropdown-task-status${
              status !== TasksStatus.TODO ? ' sidebar-dropdown-task-status--with-status' : ''
            }`
      }
    >
      <RenderCondition condition={Number(count) > 0}>
        <span className="sidebar-dropdown-task-status-tutor__count">{count}</span>
      </RenderCondition>
      {returnStatusCompoent(status)}
    </div>
  );
};

export default SidebarDropdownTaskStatus;
