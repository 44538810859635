/* eslint-disable max-len */
import React, { ComponentProps } from 'react';

export const IcLogo = (props: ComponentProps<'svg'>) => (
  <svg width="100" height="54" viewBox="0 0 100 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.5059 32.569C15.4796 32.569 17.3422 34.8452 17.3422 38.8606V46.0214C14.4866 47.8428 12.4166 48.5462 9.93352 48.5462C7.32588 48.5462 5.79464 47.4286 5.79464 45.4003C5.79464 42.8755 7.94692 41.9653 12.2515 41.9653C12.9137 41.9653 13.8656 42.0065 14.2792 42.0482V38.0333C13.6999 37.9505 12.5412 37.8681 11.4648 37.8681C4.96676 37.8681 0 40.6826 0 45.6905C0 50.6984 3.26984 53.5546 9.313 53.5546C12.1275 53.5546 15.3145 52.3959 17.6324 50.8641L18.2946 52.685C18.3775 52.9336 18.5432 53.0576 18.8328 53.0576H23.3031V38.7778C23.3031 31.7416 19.4539 27.1885 12.252 27.1885C7.36809 27.1885 3.55952 29.1757 1.32493 31.8661L5.33981 34.6389C6.62305 33.7694 7.69892 33.2317 8.48565 32.9832C9.189 32.7763 10.0575 32.569 11.5059 32.569ZM86.7133 47.76C82.9469 47.76 80.0496 44.821 80.0496 40.3924C80.0496 35.9639 82.9469 32.9837 86.7133 32.9837C90.4796 32.9837 93.3769 35.9227 93.3769 40.3513C93.3769 44.7798 90.4796 47.76 86.7133 47.76ZM86.7133 27.1885C79.3046 27.1885 73.4271 33.1077 73.4271 40.3924C73.4271 47.6771 79.304 53.5546 86.7133 53.5546C94.1225 53.5546 100 47.636 100 40.3513C100 33.0665 94.1225 27.1885 86.7133 27.1885ZM60.6374 47.7605C56.5814 47.7605 54.0566 44.5735 54.0566 40.3929C54.0566 36.2124 56.7054 32.9842 60.3889 32.9842C61.5064 32.9842 62.376 33.1499 63.0794 33.4396C63.9484 33.8121 64.9836 34.2675 66.3492 35.2193L70.6121 32.1152C68.253 29.011 64.942 27.1896 60.058 27.1896C53.3115 27.1896 47.3512 32.5289 47.3512 40.2278C47.3512 47.9267 52.4008 53.5557 60.4717 53.5557C65.3145 53.5557 68.6672 51.6519 71.0268 48.5889L66.8051 45.5264C65.4807 46.5195 64.4043 46.9754 63.5347 47.3479C62.7485 47.6787 61.7966 47.7605 60.6374 47.7605ZM35.6791 53.0597V35.2827C37.8725 33.6686 41.4326 33.0064 43.626 33.1721L45.9851 27.4186C45.1572 27.2529 44.1642 27.2117 43.4603 27.2117C40.7698 27.2117 37.4172 28.495 34.8512 30.0262L34.189 28.2048C34.1062 27.9563 33.941 27.8323 33.6513 27.8323H29.2639V53.0597H35.6791Z"
      fill="#071636"
    />
    <path
      d="M49.0486 2.52094C49.0613 2.14631 48.9632 1.65929 48.3305 1.13903C47.6625 0.665199 47.1665 0.697913 46.8082 0.808192L29.4766 5.4953C29.4766 5.4953 32.3053 10.8219 37.0742 8.90074C41.8436 6.97904 46.6246 3.36993 46.6246 3.36993C46.6246 3.36993 44.3942 8.92976 43.7896 14.0358C43.1849 19.1419 49.0666 20.4752 49.0666 20.4752L49.0486 2.52094Z"
      fill="#F05038"
    />
    <path
      d="M53.0461 0.808192C52.6884 0.697913 52.1924 0.665199 51.5244 1.13903C50.8923 1.65929 50.7941 2.14684 50.8063 2.52094L50.7878 20.4747C50.7878 20.4747 56.6695 19.1408 56.0653 14.0353C55.4606 8.92923 53.2303 3.3694 53.2303 3.3694C53.2303 3.3694 58.0113 6.97852 62.7802 8.90021C67.5496 10.8214 70.3778 5.49477 70.3778 5.49477L53.0461 0.808192Z"
      fill="#437FC2"
    />
  </svg>
);
